import React, { useContext, useEffect, useState } from "react";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { toast } from "react-toastify";
import "./New.scss";
import { MenuContext } from "../../context/MenuContext";
import axios from "../../API/axios";
const GENERAL_BANNER = "/admin/upload-generalbanner";

const NewBanner = ({ title }) => {
  const { setSucces2, generalBanner, setGeneralBanner } =
    useContext(MenuContext);
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [localGen, setLocalGen] = useState(null);
  // const generalBanner = JSON.parse(localStorage.getItem("General"));

  const imgSizing = () => {
    if (image?.size > 2000000) {
      return toast.error("file size is too large, image should not exceed 2mb");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("image", image);

    if (imgSizing()) {
      return;
    }

    // console.log([...formData]);
    setIsLoading(true);
    try {
      const response = await axios.post(GENERAL_BANNER, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          token: `${JSON.parse(localStorage.getItem("data22"))}`,
        },
      });
      setIsLoading(false);
      console.log(response?.data);
      setLocalGen(response?.data?.data);
      setSucces2((prev) => !prev);
      if (response?.data.msg) {
        toast(response?.data.msg);
        // console.log(response?.data.msg);
      }
    } catch (error) {
      if (!error?.response) {
        toast.error("No Server Response");
        setIsLoading(false);
      } else if (error.response?.status === 500) {
        // setErrMsg("");
        toast.error("File too large, image should not be more than 2mb");
        setIsLoading(false);
      }
    }
  };

  const handleImage = (event) => {
    setImage(event.target.files[0]);
  };

  useEffect(() => {
    setGeneralBanner(JSON.parse(localStorage.getItem("generalBanner")));
  }, []);

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>{title}</h1>
        </div>
        <div className="bottom">
          <div className="left" style={{ margin: "10px 20px" }}>
            <img
              src={
                image
                  ? URL.createObjectURL(image)
                  : generalBanner
                  ? generalBanner
                  : "https://t4.ftcdn.net/jpg/04/70/29/97/360_F_470299797_UD0eoVMMSUbHCcNJCdv2t8B2g1GVqYgs.jpg"
              }
              alt=""
            />
          </div>
          <div className="right">
            <form action="" onSubmit={handleSubmit}>
              <div className="formInput">
                Upload New Banner
                <label htmlFor="file" className="icon">
                  <FileUploadIcon />
                </label>
                <input
                  type="file"
                  // onChange={(e) => setFile(e.target.files[0])}
                  name="image"
                  onChange={handleImage}
                  id="file"
                  style={{ display: "none" }}
                />
              </div>
              <button>{isLoading ? "uploading.." : "update"}</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewBanner;

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import Single from "./pages/single/Single";
import List from "./pages/list/List";
import { userInputs } from "./formSource";
import "./style/Dark.scss";
import { useContext } from "react";
import { DarkModeContext } from "./context/darkModeContext";

import { MenuContext } from "./context/MenuContext";
import List2 from "./pages/list2/List2";
import NewBanner from "./pages/new/NewBanner";
import NewSeen from "./pages/new/NewSeen";
import NewTrip from "./pages/new/NewTrip";
import TKEService from "./pages/TKEService/TKEService";
import ApproveUser from "./pages/ApproveUser/ApproveUser";
import SendReceipt from "./pages/SendReceipt/SendReceipt";
import AddNewUser from "./pages/AddNewUser/AddNewUser";
import { Layout } from "./Layout";
import RequireAuth from "./RequireAuth";
import CreateAdmin from "./pages/CreateAdmin/CreateAdmin";
import EditUser from "./pages/EditUser/EditUser";

import CreateNewUser01 from "./pages/AddNewUser/CreateNewUser01";
import ResetOtherAdmin from "./pages/CreateAdmin/ResetOtherAdmin";
import ForgotPassword from "./pages/login/ForgotPassword";
import ResetPassword from "./pages/login/ResetPassword";

function App() {
  const { darkMode } = useContext(DarkModeContext);
  // const { dispatchMenu } = useContext(MenuContext);
  // console.log("name is ", useParams());

  return (
    <div className={darkMode ? "app" : "app dark"}>
      <BrowserRouter>
        <Routes>
          <Route path="login" element={<Login />} />
          <Route path="forgot_password" element={<ForgotPassword />} />
          <Route path="reset_password" element={<ResetPassword />} />
          <Route path="login" element={<Login />} />
          <Route path="/" element={<Layout />}>
            <Route element={<RequireAuth />}>
              <Route path="/" element={<Home />} />
              <Route
                path="banner"
                element={<NewBanner title="Upload New Banner" />}
              />
              <Route path="users">
                <Route index element={<List />} />
              </Route>
              <Route path="trips">
                <Route index element={<List2 />} />
              </Route>
              <Route path="newUser" element={<AddNewUser />} />
              <Route path="register-user" element={<CreateNewUser01 />} />
              <Route path="EditUser" element={<EditUser />} />
              <Route path="createAdmin" element={<CreateAdmin />} />
              <Route path="ResetOtherAdmin" element={<ResetOtherAdmin />} />
              <Route path="user/:emailId" element={<Single />} />
              <Route
                path="trip/:id"
                element={<NewTrip inputs={userInputs} title="Publish Trip" />}
              />
              <Route
                path="services/:id"
                element={<TKEService inputs={userInputs} title="" />}
              />
              <Route path="sendReceipt/:id" element={<SendReceipt />} />
              <Route
                path="approve/:id"
                element={<NewSeen title="Trip Details" />}
              />
              <Route path="approveUser/:id" element={<ApproveUser />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

import React from "react";

const BespokeList = ({ trip }) => {
  return (
    <>
      {" "}
      <div>
        <div style={{ margin: "1.3rem" }}>
          <span>first name:</span>
          <input
            value={trip?.user?.firstName}
            disabled
            style={{ textTransform: "capitalize" }}
          />
        </div>
        <div style={{ margin: "1.3rem" }}>
          <span>Last name:</span>
          <input
            value={trip?.user?.lastName}
            disabled
            style={{ textTransform: "capitalize" }}
          />
        </div>
        <div style={{ margin: "1.3rem" }}>
          <span>Email:</span>
          <input value={trip?.user?.email} disabled />
        </div>
        <div style={{ margin: "1.3rem" }}>
          <span> Phone Number:</span>
          <input value={trip?.user?.phoneNumber} disabled />
        </div>
        <div style={{ margin: "1.3rem" }}>
          <span>User Status:</span>
          <input value={trip?.user?.userType} disabled />
        </div>
        <div style={{ margin: "1.3rem" }}>
          <span>About trip:</span>
          <input value={trip?.tripDetails?.aboutTrip} disabled />
        </div>
        <div style={{ margin: "1.3rem" }}>
          <span>Date Of Travelling:</span>
          <input value={trip?.tripDetails?.enterDateToTravel} disabled />
        </div>
        <div style={{ margin: "1.3rem" }}>
          <span>Date Of Arrival:</span>
          <input value={trip?.tripDetails?.enterDateToArrive} disabled />
        </div>
        <div style={{ margin: "1.3rem" }}>
          <span>Any Set date:</span>
          <input value={trip?.tripDetails?.anyDateSet} disabled />
        </div>
        <div style={{ margin: "1.3rem" }}>
          <span>Booking time:</span>
          <input value={trip?.tripDetails?.bookingTime} disabled />
        </div>
      </div>
      <div>
        <div style={{ margin: "1.3rem" }}>
          <span>Budget For Entire Trip:</span>
          <input value={trip?.tripDetails?.budgetForEntireTrip} disabled />
        </div>
        <div style={{ margin: "1.3rem" }}>
          <span>Choice Activities While Travelling:</span>
          {trip?.tripDetails?.choiceActivityWhileTravelling[0] && (
            <input
              value={trip?.tripDetails?.choiceActivityWhileTravelling[0]}
              disabled
            />
          )}
        </div>
        {trip?.tripDetails?.choiceActivityWhileTravelling[1] && (
          <div style={{ margin: "-1rem 1.3rem 1.3rem 1.3rem" }}>
            <input
              value={trip?.tripDetails?.choiceActivityWhileTravelling[1]}
              disabled
            />
          </div>
        )}
        {trip?.tripDetails?.choiceActivityWhileTravelling[2] && (
          <div style={{ margin: "-1rem 1.3rem 1.3rem 1.3rem" }}>
            <input
              value={trip?.tripDetails?.choiceActivityWhileTravelling[2]}
              disabled
            />
          </div>
        )}
        {trip?.tripDetails?.choiceActivityWhileTravelling[3] && (
          <div style={{ margin: "-1rem 1.3rem 1.3rem 1.3rem" }}>
            <input
              value={trip?.tripDetails?.choiceActivityWhileTravelling[3]}
              disabled
            />
          </div>
        )}
        {trip?.tripDetails?.choiceActivityWhileTravelling[4] && (
          <div style={{ margin: "-1rem 1.3rem 1.3rem 1.3rem" }}>
            <input
              value={trip?.tripDetails?.choiceActivityWhileTravelling[4]}
              disabled
            />
          </div>
        )}
        <div style={{ margin: "1.3rem" }}>
          <span>days Likely To Spend:</span>
          <input value={trip?.tripDetails?.daysLikelyToSpend} disabled />
        </div>
        <div style={{ margin: "1.3rem" }}>
          <span>Idea Month To Travel:</span>
          <input value={trip?.tripDetails?.ideaMonthYear} disabled />
        </div>
        <div style={{ margin: "1.3rem" }}>
          <span>Need Visa:</span>
          <input value={trip?.tripDetails?.needVisa ? "Yes" : "No"} disabled />
        </div>
        <div style={{ margin: "1.3rem" }}>
          <span>Num. Travelling Adults:</span>
          <input value={trip?.tripDetails?.numberOfTravellerAdult} disabled />
        </div>
        <div style={{ margin: "1.3rem" }}>
          <span>Num. Travelling Children:</span>
          <input value={trip?.tripDetails?.numberOfTravellerKids} disabled />
        </div>
        <div style={{ margin: "1.3rem" }}>
          <span>What Are You travelling For:</span>
          <input value={trip?.tripDetails?.travelForWhat} disabled />
        </div>
        <div style={{ margin: "1.3rem" }}>
          <span>Who Are You travelling With:</span>
          <input value={trip?.tripDetails?.travelWithWho} disabled />
        </div>
        <div style={{ margin: "1.3rem" }}>
          <span>Travelling To What City:</span>
          <input value={trip?.tripDetails?.whatCity} disabled />
        </div>
        <div style={{ margin: "1.3rem" }}>
          <span>Travelling To What Country:</span>
          <input value={trip?.tripDetails?.whereTo} disabled />
        </div>
      </div>
    </>
  );
};

export default BespokeList;

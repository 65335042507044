import React, { useContext, useEffect, useState } from "react";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import { useParams } from "react-router-dom";
import { MenuContext } from "../../context/MenuContext";
import { Button, TextField } from "@mui/material";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "./TKEService.scss";
import axios from "../../API/axios";
import { toast } from "react-toastify";
const MEMBERSHIP = "/admin/update-membership";

const TKEService = ({ title, inputs }) => {
  const { isLoading, setIsLoading, userToCreate } = useContext(MenuContext);
  const [body1, SetBody1] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [membershipType, setMembershipType] = useState(
    userToCreate.membership ? userToCreate.membership?.membershipType : ""
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await axios.patch(
        MEMBERSHIP,
        {
          membership: {
            membershipType: membershipType,
            benefits: body1,
          },
          email: userToCreate?.email,
        },
        {
          headers: {
            "Content-Type": "application/json",
            token: `${JSON.parse(localStorage.getItem("data22"))}`,
          },
        }
      );
      setErrMsg(response?.data?.msg);
      setIsLoading(false);
      toast(response?.data?.msg);
      console.log(response?.data);
    } catch (err) {
      // console.log(err?.response?.data?.code);
      if (!err?.response) {
        setErrMsg("No Server Response");
        setIsLoading(false);
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
        console.log(errMsg);
        setIsLoading(false);
      } else {
        setErrMsg("Sending Failed");
        setIsLoading(false);
      }
    }
  };

  // console.log(userToCreate.membership);

  return (
    <div className="newTrip">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div style={{ marginTop: "2rem" }} className="bottom">
          <div className="left">
            <div
              className="center"
              style={{
                textAlign: "center",
                padding: "0.2rem 1rem",
                boxShadow: "2px 4px 10px 1px rgba(0,0,0,.3)",
                margin: "20px 5%",
              }}
            >
              <form className="" onSubmit={handleSubmit}>
                {/* <TextField
                  style={{ border: "none", margin: "1rem 0" }}
                  fullWidth
                  label="title"
                  name="membershipType"
                  id="fullWidth"
                  onChange={(e) => {
                    setMembershipType(e.target.value);
                  }}
                  value={membershipType}
                /> */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: "1rem 0",
                    alignItems: "flex-start",
                  }}
                  className="form-group"
                >
                  <label className="profile-label">Title</label>
                  <input
                    style={{
                      height: "40px",
                      width: "300px",
                      outline: "none",
                      marginTop: "0.3rem",
                    }}
                    type="text"
                    className="form-control profile-input"
                    name="membershipType"
                    onChange={(e) => {
                      setMembershipType(e.target.value);
                    }}
                    defaultValue={membershipType}
                  />
                </div>
                <CKEditor
                  editor={ClassicEditor}
                  data={
                    userToCreate.membership
                      ? userToCreate.membership?.benefits
                      : "<p>Hello!</p>"
                  }
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    // console.log("Editor is ready to use!", editor);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    SetBody1(data);
                    // console.log({ event, editor, data });
                  }}
                  onBlur={(event, editor) => {
                    // console.log("Blur.", editor);
                  }}
                  onFocus={(event, editor) => {
                    // console.log("Focus.", editor);
                  }}
                />
                <button
                  style={{
                    display: "block",
                    margin: "1rem",
                    padding: "0.5rem 1rem",
                    border: "none",
                    background: "#7f53a8",
                    color: "white",
                    fontWeight: "700",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                >
                  {isLoading ? "Loading..." : "Submit"}
                </button>
              </form>
              <p>{errMsg}</p>
            </div>
            <div style={{ visibility: "hidden" }} className="left2">
              <div className="" style={{ padding: "1rem" }}>
                <div dangerouslySetInnerHTML={{ __html: body1 }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TKEService;

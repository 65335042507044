import React, { useContext, useState } from "react";
import "./Login.scss";
// import axios from "axios";
import axios from "../../API/axios";
import { useNavigate } from "react-router-dom";
import { MenuContext } from "../../context/MenuContext";
//
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Stack } from "@mui/material";
import { toast } from "react-toastify";
const RESET_URL = "/admin/reset-super-admin-password";

const ResetPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [values, setValues] = useState({
    resetCode: null,
    password: "",
  });
  const { recoverToken } = useContext(MenuContext);
  const [errMsg, setErrMsg] = useState("");
  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const { resetCode, password } = values;
    // Number(
    try {
      const response = await axios.post(
        RESET_URL,
        { password, resetCode: Number(resetCode) },
        {
          headers: {
            "Content-Type": "application/json",
            token: recoverToken,
          },
        }
      );
      toast.success(response?.data?.msg);
      setErrMsg(response?.data?.msg);
      setIsLoading(false);
      navigate("/");
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
        setIsLoading(false);
      } else if (err.response?.status === 400) {
        setErrMsg("incorrect Login Id");
        setIsLoading(false);
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
        console.log(errMsg);
        setIsLoading(false);
      } else {
        setErrMsg("Failed, Check Input");
        setIsLoading(false);
      }
    }
  };
  console.log(values?.resetCode);
  return (
    <div className="cont__login">
      <div className="login">
        <Stack
          sx={{
            color: "blue",
            fontWeight: 600,
            fontSize: "1.3rem",
            margin: "1rem 0",
          }}
        >
          Reset Super Admin Password
        </Stack>
        <form className="format">
          <TextField
            fullWidth
            label="Password"
            name="password"
            id="fullWidth"
            style={{ margin: "1rem 0" }}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            label="Reset Code"
            name="resetCode"
            id="fullWidth"
            style={{ margin: "1rem 0" }}
            onChange={handleChange}
          />
          <Button variant="contained" onClick={handleSubmit}>
            {isLoading ? "Loading" : "Submin"}
          </Button>
        </form>
        <p style={{ color: "red" }}>{errMsg}</p>
      </div>
    </div>
  );
};

export default ResetPassword;

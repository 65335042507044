import React, { useContext, useEffect, useState } from "react";
// import DataTables from "../../components/datatables/DataTables";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import "./List2.scss";
import { MenuContext } from "../../context/MenuContext";
import { useLocation } from "react-router-dom";
import TableComponent from "../../components/table/TableComponent";
import ReactPaginate from "react-paginate";

const List2 = () => {
  const { dispatchMenu, tripRequests, isLoading } = useContext(MenuContext);
  const location = useLocation();
  // console.log("Location ", location.pathname);
  useEffect(() => {
    dispatchMenu({ type: "CLOSE" });
  }, [location.pathname]);
  //PAGINATE
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 10;
  // console.log(currentItems);
  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(tripRequests?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(tripRequests.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, tripRequests]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % tripRequests.length;
    setItemOffset(newOffset);
  };
  return (
    <div className="list list2">
      <Sidebar />
      <div className="ListContainer">
        <Navbar />
        <div style={{ margin: "2rem 1rem" }} className="">
          <h4 className="">Trip Requests</h4>
        </div>
        <TableComponent rows={currentItems} isLoading={isLoading} />

        <div>
          <ReactPaginate
            breakLabel="..."
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="< previous"
            renderOnZeroPageCount={null}
            // styling the whole btns, note: always know when to use class with link a tags and without for just li tags
            containerClassName="pagination1"
            pageLinkClassName="page-num"
            previousLinkClassName="page-num"
            nextLinkClassName="page-num"
            activeLinkClassName="active"
          />
        </div>
      </div>
    </div>
  );
};

export default List2;

import axios from "axios";
// https://tke-travel.onrender.com/api/v1/questionnaires/get-all

export default axios.create({
  baseURL: "https://tke-travel.onrender.com/api/v1",
  // baseURL: "https://tke-travel.herokuapp.com/api/v1",
  // headers: {
  //   "Content-type": "application/json",
  // },
});

import React, { useContext } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { MenuContext } from "./context/MenuContext";

const RequireAuth = ({ children }) => {
  const location = useLocation();
  const { succes } = useContext(MenuContext);
  const authToken = JSON.parse(localStorage.getItem("data22"));
  // const authToken = succes;

  return authToken ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
  //   if (!authToken) {
  //     return <Navigate to="/login" replace />;
  //   }
  //   return children;
};

export default RequireAuth;

import React from "react";
import "./Widget2.scss";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
// import PersonIcon from "@mui/icons-material/Person";
// import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import LocalAirportSharpIcon from "@mui/icons-material/LocalAirportSharp";
const Widgets = ({ type, tripCount, tripCount1 }) => {
  let data;

  // const amount = 345;
  // const diff = 30;

  switch (type) {
    case "Upcoming Trip":
      data = {
        title: "Upcoming Trip",
        isMoney: false,
        link: "See all",
        icon: (
          <LocalAirportSharpIcon
            className="icon"
            fontSize="small"
            style={{ backgroundColor: "rgba(255,0,0,.2)" }}
          />
        ),
        amount: tripCount,
      };
      break;
    case "Total Trip":
      data = {
        title: "Total Trip",
        isMoney: false,
        link: "See all",
        icon: (
          <LocalAirportSharpIcon
            className="icon"
            fontSize="small"
            style={{ backgroundColor: "#6433d833" }}
          />
        ),
        amount: tripCount1,
      };
      break;
    default:
      console.log("nothing...");
      break;
  }
  return (
    <div className="widget2">
      <div className="left">
        <div className="title">{data.title}</div>
        <div className="counter">
          {/* {data.isMoney && "$"} */}
          {data.amount}
        </div>
        {/* <span className="link">See All Users</span> */}
      </div>
      <div className="right">
        <div className="percentage positive">
          {data.amount >= 1 && (
            <ArrowUpwardIcon style={{ visibility: "hidden" }} />
          )}
          {data.amount <= 0 && (
            <ArrowDownwardIcon style={{ visibility: "hidden" }} />
          )}
          {/* {diff}% */}
          &nbsp;
        </div>
        {data.icon}
      </div>
    </div>
  );
};

export default Widgets;

import React, { useState } from "react";
import "./Agreement.scss";
import axios from "../../../API/axios";
import AgreementTable from "../../table/tripTables/AgreementTable";
import { toast } from "react-toastify";
const AGREEMENT_URL = "/trips/agreements";

const Agreement = ({ _id, trip, setFetch }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [local, setLocal] = useState({});
  // const [datas, setDatas] = useState(trip[0]?.agreements);
  const datas = trip[0]?.agreements;
  const [values, setValues] = useState({
    title: "",
    document_link: "",
    status: "",
  });
  const [errors, setError] = useState({});

  const validateForm = () => {
    const { title, document_link } = values;
    let errors = {};
    let formIsValid = true;

    if (!title) {
      formIsValid = false;
      errors["title"] = "*Please enter title.";
    }
    if (!document_link) {
      formIsValid = false;
      errors["document_link"] = "*Please enter agreement link.";
    }

    setError(errors);
    return formIsValid;
  };

  const editItem = (id) => {
    const idPos = datas?.findIndex((item) => item._id === id);
    if (idPos >= 0) {
      return setLocal(datas[idPos]);
    }
  };
  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  // const updateDatas = () => {
  //   const idPos = datas?.findIndex((item) => item._id === local._id);
  //   if (idPos >= 0) {
  //     datas[idPos] = values;
  //     toast("Changes Made, Please Save!");
  //     setLocal({});
  //     setTrigger((prevTrigger) => prevTrigger + 1);
  //   }
  // };

  // const deleteFromDatas = (id) => {
  //   const idPos = datas?.findIndex((item) => item._id === id);
  //   if (idPos >= 0) {
  //     setDatas(datas.filter((item) => item._id !== id));
  //     // datas[idPos] = values;
  //     toast("Item deleted, Please Save!");
  //   }
  // };

  // const addAgreement = () => {
  //   return setDatas((prev) => [
  //     ...prev,
  //     {
  //       title: values.title,
  //       document_link: values.document_link,
  //       status: values.status ? values.status : "Not Signed",
  //     },
  //   ]);
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return toast.error("enter all input fields");
    }

    const { title, document_link, status } = values;
    const data = {
      _id: _id,
      agreement_details: {
        title: title,
        document_link: document_link,
        status: status ? status : "Not Signed",
      },
    };

    setIsLoading(true);

    try {
      const response = await axios.post(AGREEMENT_URL, data, {
        headers: {
          token: `${JSON.parse(localStorage.getItem("data22"))}`,
        },
      });
      setIsLoading(false);
      if (response?.data.msg) {
        toast(response?.data.msg);
        setLocal({});
        setFetch((prev) => !prev);
      }
    } catch (err) {
      // console.log(err?.response?.data?.code);
      if (err?.response?.data?.code === 409) {
        setErrMsg("User With This Email Already Exist");
        setIsLoading(false);
      } else if (!err?.response) {
        setErrMsg("No Server Response");
        setIsLoading(false);
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
        console.log(errMsg);
        setIsLoading(false);
      } else {
        setErrMsg("Login Failed");
        setIsLoading(false);
      }
    }
  };

  // useEffect(() => {
  //   if (Object.keys(local).length > 0) {
  //     setValues({
  //       title: local?.title,
  //       document_link: local?.document_link,
  //       status: local?.status,
  //     });
  //   } else {
  //     setValues({
  //       title: "",
  //       document_link: "",
  //       status: "",
  //     });
  //   }
  // }, [local, trigger]);

  return (
    <div className="left">
      <div
        className="center center1"
        style={{
          textAlign: "center",
          padding: "0.2rem 1rem",
          position: "relative",
        }}
      >
        <h4 style={{ margin: "1rem" }}>Agreement</h4>
        <form className="" onSubmit={handleSubmit}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              margin: "1rem 0",
            }}
            className="form-group"
          >
            <label className="profile-label">Document title</label>
            <input
              id="myInput1"
              style={{ height: "40px", outline: "none", marginTop: "0.3rem" }}
              type="text"
              className="form-control profile-input"
              name="title"
              onChange={handleChange}
              defaultValue={local && local.title ? local.title : values?.title}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              margin: "1rem 0",
            }}
            className="form-group"
          >
            <label className="profile-label">Agreement Link</label>
            <input
              id="myInput2"
              style={{ height: "40px", outline: "none", marginTop: "0.3rem" }}
              type="text"
              className="form-control profile-input"
              name="document_link"
              onChange={handleChange}
              defaultValue={values?.document_link}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              margin: "1rem 0",
            }}
            className="form-group"
          >
            <label className="profile-label">Status</label>
            <select
              style={{ height: "40px", outline: "none", marginTop: "0.3rem" }}
              className="form-control profile-input"
              defaultValue={values?.status}
              name="status"
              onChange={handleChange}
            >
              <option value="Not Signed">Not Signed</option>
              <option value="Signed">Signed</option>
            </select>
          </div>
          {
            <button
              variant="contained"
              style={{
                display: "block",
                margin: "2rem 1rem 0rem 1rem",
                border: "none",
                padding: "0.5rem 1rem",
                color: "white",
                background: "blue",
                borderRadius: "10px",
                fontSize: "1.3rem",
                cursor: "pointer",
              }}
            >
              {isLoading ? "saving..." : "save"}
            </button>
          }
        </form>
      </div>
      <div className="ine">
        <AgreementTable
          row={datas}
          editItem={editItem}
          setFetch={setFetch}
          setLocal={setLocal}
          _id={_id}
          local={local}
          // deleteFromDatas={deleteFromDatas}
        />
      </div>
    </div>
  );
};

export default Agreement;

{
  /* <AgreementTable row={local ? local : trip[0]?.agreements} /> */
}

import React, { useState, useContext } from "react";
import { MenuContext } from "../../../context/MenuContext";
import { TextField, Button } from "@mui/material";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "../Itenary/Itenary.scss";
import axios from "../../../API/axios";
import { toast } from "react-toastify";
const DOC_REQUEST = "/trips/additional-documents/request";

const UploadInstruction = ({ _id, trip, setFetch }) => {
  const [body1, SetBody1] = useState("");

  const { setSucces2 } = useContext(MenuContext);
  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  console.log(trip[0]?.additionalDocuments);
  // console.log(body1);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await axios.post(
        DOC_REQUEST,
        {
          additionalDocumentRequests: body1,
          _id: _id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            token: `${JSON.parse(localStorage.getItem("data22"))}`,
          },
        }
      );
      setIsLoading(false);
      setFetch((prev) => !prev);
      // console.log(response);
      if (response?.data.msg) {
        toast(response?.data.msg);
        // console.log(response?.data.msg);
      }
    } catch (err) {
      // console.log(err?.response?.data?.code);
      if (err?.response?.data?.code === 409) {
        setErrMsg("Error");
        setIsLoading(false);
      } else if (!err?.response) {
        setErrMsg("No Server Response");
        setIsLoading(false);
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
        console.log(errMsg);
        setIsLoading(false);
      } else {
        setErrMsg("Failed");
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="left">
      <div
        className="center center1"
        style={{ textAlign: "center", padding: "0.2rem 1rem" }}
      >
        <h4 style={{ margin: "1rem" }}>Documents Upload Instruction</h4>
        <form className="" onSubmit={handleSubmit}>
          <CKEditor
            editor={ClassicEditor}
            // data="<p>Hello!</p>"
            data={
              trip[0]?.additionalDocumentRequests
                ? trip[0]?.additionalDocumentRequests
                : "<p>Hello!</p>"
            }
            onReady={(editor) => {
              // You can store the "editor" and use when it is needed.
              // console.log("Editor is ready to use!", editor);
            }}
            onChange={(event, editor) => {
              const data = editor.getData();
              SetBody1(data);
            }}
            onBlur={(event, editor) => {
              // console.log("Blur.", editor);
            }}
            onFocus={(event, editor) => {
              // console.log("Focus.", editor);
            }}
          />

          <button
            variant="contained"
            style={{
              display: "block",
              margin: "1rem",
              border: "none",
              padding: "0.5rem 1rem",
              color: "white",
              background: "blue",
              borderRadius: "10px",
              fontSize: "1.3rem",
              cursor: "pointer",
            }}
          >
            {isLoading ? "sending..." : "submit"}
          </button>
        </form>
      </div>
      <div className="left2">
        <div className="" style={{ padding: "1rem" }}>
          {trip[0]?.additionalDocuments?.map((item, index) => {
            return (
              <div
                key={index}
                style={{
                  boxShadow: "2px 4px 10px 1px rgba(0,0, 0,.3)",
                  margin: "0.7rem 0.1rem",
                  padding: "0.5rem 0.2rem",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0 0.2rem",
                  }}
                  className=""
                >
                  <span className="">{item?.title}</span>
                  <a href={item?.document?.link} target="_blank" className="">
                    View Doc.
                  </a>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default UploadInstruction;

import React, { useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "./TableComponent.scss";
import { Link } from "react-router-dom";
import moment from "moment/moment";

const TableComponentDashboard = ({ rows }) => {
  return (
    <TableContainer
      component={Paper}
      style={{ margin: "1rem", maxHeight: "400px" }}
      className="table"
      // sx={{ minHeight: 650 }}
    >
      <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell className="tableCell" align="center">
              #
            </TableCell>
            <TableCell className="tableCell" align="center">
              First Name
            </TableCell>
            <TableCell className="tableCell" align="center">
              Last Name
            </TableCell>
            <TableCell className="tableCell" align="center">
              Email
            </TableCell>
            <TableCell className="tableCell" align="center">
              Resquest Date:
            </TableCell>
            <TableCell className="tableCell" align="center">
              Trip Type
            </TableCell>
            <TableCell className="tableCell" align="center">
              User Type
            </TableCell>
            <TableCell className="tableCell" align="center">
              Status
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row, index) => {
            return (
              <TableRow key={row?._id}>
                <TableCell className="tableCell" align="center">
                  {index + 1}
                </TableCell>
                <TableCell className="tableCell" align="center">
                  <div className="cellWrapper">
                    <Link
                      style={{ textDecoration: "none" }}
                      to={`approve/${row?._id}`}
                    >
                      {row?.user?.firstName}
                    </Link>
                  </div>
                </TableCell>
                <TableCell className="tableCell" align="center">
                  {row?.user?.lastName}
                </TableCell>
                <TableCell className="tableCell" align="center">
                  {row?.user?.email}
                </TableCell>
                <TableCell className="tableCell" align="center">
                  {moment(row?.createdAt).format("L")}
                </TableCell>
                <TableCell className="tableCell" align="center">
                  {row?.tripType}
                </TableCell>
                <TableCell className="tableCell" align="center">
                  {row?.user?.userType}
                </TableCell>
                <TableCell className="tableCell" align="center">
                  {row?.requestStatus === "new" && (
                    <span
                      style={{
                        background: "#efd096de",
                        color: "gray",
                        padding: "0.2rem 0.6rem",
                      }}
                    >
                      {row?.requestStatus}
                    </span>
                  )}
                </TableCell>
                {/* <TableCell className="tableCell" align="center">
                <Link to={`approve/${row.id}`}>
                  {" "}
                  <span className={`status`}>View</span>
                </Link>
              </TableCell> */}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableComponentDashboard;

import React from "react";
import moment from "moment";

const DestinationCel = ({ trip }) => {
  return (
    <>
      {" "}
      <div>
        <div style={{ margin: "1.3rem" }}>
          <span>first name:</span>
          <input
            value={trip?.user?.firstName}
            disabled
            style={{ textTransform: "capitalize" }}
          />
        </div>
        <div style={{ margin: "1.3rem" }}>
          <span>Last name:</span>
          <input
            value={trip?.user?.lastName}
            disabled
            style={{ textTransform: "capitalize" }}
          />
        </div>
        <div style={{ margin: "1.3rem" }}>
          <span>Email:</span>
          <input value={trip?.user?.email} disabled />
        </div>
        <div style={{ margin: "1.3rem" }}>
          <span> Phone Number:</span>
          <input value={trip?.user?.phoneNumber} disabled />
        </div>
        <div style={{ margin: "1.3rem" }}>
          <span>User Status:</span>
          <input value={trip?.user?.userType} disabled />
        </div>
        <div style={{ margin: "1.3rem" }}>
          <span>Occation Celeberating:</span>
          <input
            value={trip?.tripDetails?.occationYouAreCelebrating}
            disabled
          />
        </div>
        <div style={{ margin: "1.3rem" }}>
          <span>Where would you like to Celebrate:</span>
          <input value={trip?.tripDetails?.likeToCelebrateWhat} disabled />
        </div>
        <div style={{ margin: "1.3rem" }}>
          <span>Estimated Guests:</span>
          <input
            value={trip?.tripDetails?.estimateGuestToCelebrateWith}
            disabled
          />
        </div>
        <div style={{ margin: "1.3rem" }}>
          <span>Num. Adult On the Occation:</span>
          <input value={trip?.tripDetails?.AdultsCeleberatingWith} disabled />
        </div>
        <div style={{ margin: "1.3rem" }}>
          <span>Num. Children On the Occation:</span>
          <input value={trip?.tripDetails?.childrenCelebratingWith} disabled />
        </div>
        <div style={{ margin: "1.3rem" }}>
          <span>City Most Likely Expecting Guest:</span>
          <input
            value={trip?.tripDetails?.whatCityYouMostLikelyExpectQuests}
            disabled
          />
        </div>
      </div>
      <div>
        <div style={{ margin: "1.3rem" }}>
          <span>Num. Days Expecting to Spend During The Celebration:</span>
          <input value={trip?.tripDetails?.daysToSpendCelebrating} disabled />
        </div>
        <div style={{ margin: "1.3rem" }}>
          <span>Set date For The Occation/Celebration:</span>
          <input
            value={moment(trip?.tripDetails?.setDateForCel).format("L")}
            disabled
          />
        </div>
        {/* <div style={{ margin: "1.3rem" }}>
          <span>Chosen TKE Services:</span>
          <input value={trip?.tripDetails?.whatTKEServiceDoYouWant} disabled />
        </div> */}
        {/*  */}

        <div style={{ margin: "1.3rem" }}>
          <span>Chosen TKE Services:</span>
          {trip?.tripDetails?.whatTKEServiceDoYouWant[0] && (
            <input
              value={trip?.tripDetails?.whatTKEServiceDoYouWant[0]}
              disabled
            />
          )}
        </div>
        {trip?.tripDetails?.whatTKEServiceDoYouWant[1] && (
          <div style={{ margin: "-1rem 1.3rem 1.3rem 1.3rem" }}>
            <input
              value={trip?.tripDetails?.whatTKEServiceDoYouWant[1]}
              disabled
            />
          </div>
        )}
        {trip?.tripDetails?.whatTKEServiceDoYouWant[2] && (
          <div style={{ margin: "-1rem 1.3rem 1.3rem 1.3rem" }}>
            <input
              value={trip?.tripDetails?.whatTKEServiceDoYouWant[2]}
              disabled
            />
          </div>
        )}
        {trip?.tripDetails?.whatTKEServiceDoYouWant[3] && (
          <div style={{ margin: "-1rem 1.3rem 1.3rem 1.3rem" }}>
            <input
              value={trip?.tripDetails?.whatTKEServiceDoYouWant[3]}
              disabled
            />
          </div>
        )}
        {trip?.tripDetails?.whatTKEServiceDoYouWant[4] && (
          <div style={{ margin: "-1rem 1.3rem 1.3rem 1.3rem" }}>
            <input
              value={trip?.tripDetails?.whatTKEServiceDoYouWant[4]}
              disabled
            />
          </div>
        )}

        {/*  */}
        <div style={{ margin: "1.3rem" }}>
          <span>How Soon Do You Want To Book:</span>
          <input value={trip?.tripDetails?.howSoonDoYouWantToBook} disabled />
        </div>
        <div style={{ margin: "1.3rem" }}>
          <span>Choice Of Activities:</span>
          <input
            value={trip?.tripDetails?.choiceActivityWhileTravelling[0]}
            disabled
          />
        </div>
        <div style={{ margin: "1.3rem" }}>
          <span>Is There Anything Else About The Celebration:</span>
          <input
            value={trip?.tripDetails?.anythingElseAboutCeleberation}
            disabled
          />
        </div>
      </div>
    </>
  );
};

export default DestinationCel;

import React from "react";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";

const ApproveUser = () => {
  return (
    <div className="newTrip">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div style={{ marginTop: "2rem" }} className="bottom">
          Approve User
        </div>
      </div>
    </div>
  );
};

export default ApproveUser;

import React, { useState, useContext } from "react";
import Navbar from "../../components/navbar/Navbar";
import TextField from "@mui/material/TextField";
import Sidebar from "../../components/sidebar/Sidebar";
import { MenuContext } from "../../context/MenuContext";
//
import Button from "@mui/material/Button";
import "./AddNewUser.scss";

// import axios from "axios";
import axios from "../../API/axios";
import { toast } from "react-toastify";
import { Typography } from "@mui/material";
const CREATE_USER = "/user/register";

const CreateNewUser01 = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { setSucces } = useContext(MenuContext);
  const [message, setMessage] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    age: "",
    gender: "",
    email: "",
    address: "",
    dialCode: "",
    phoneNumber: "",
  });

  const [errors, setError] = useState({});

  const validateForm = () => {
    const { firstName, lastName, dialCode, email, phoneNumber, gender } =
      values;
    let errors = {};
    let formIsValid = true;

    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    if (!firstName) {
      formIsValid = false;
      errors["firstName"] = "*Please enter first name.";
    }
    if (!lastName) {
      formIsValid = false;
      errors["lastName"] = "*Please enter last name.";
    }
    if (!dialCode) {
      formIsValid = false;
      errors["dialCode"] = "*Please enter post code.";
    }
    if (!phoneNumber) {
      formIsValid = false;
      errors["phoneNumber"] = "*Please enter phone number.";
    }
    if (!gender) {
      formIsValid = false;
      errors["gender"] = "*Please enter gender.";
    }
    if (!regex.test(email)) {
      formIsValid = false;
      errors["email"] = "*Please enter valid email.";
    }

    setError(errors);
    return formIsValid;
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return window.scrollTo(0, 0);

    setIsLoading(true);
    const {
      firstName,
      lastName,
      age,
      gender,
      email,
      address,
      dialCode,
      phoneNumber,
    } = values;

    try {
      const response = await axios.post(
        CREATE_USER,
        {
          firstName,
          lastName,
          age,
          gender,
          email,
          address,
          dialCode,
          phoneNumber,
          userType: "user",
        },
        {
          headers: {
            "Content-Type": "application/json",
            token: `${JSON.parse(localStorage.getItem("data22"))}`,
          },
        }
      );
      setMessage(response?.data);
      setIsLoading(false);
      console.log(response?.data);
      setValues({});
      if (response?.data.msg) {
        toast(response?.data.msg);
        document.getElementById("myInputU1").value = "";
        document.getElementById("myInputU2").value = "";
        document.getElementById("myInputU3").value = "";
        document.getElementById("myInputU4").value = "";
        document.getElementById("myInputU5").value = "";
        document.getElementById("myInputU6").value = "";
        document.getElementById("myInputU7").value = "";
        document.getElementById("myInputU8").value = "";
      }

      setValues({});
      setSucces((prev) => !prev);
    } catch (err) {
      // console.log(err?.response?.data?.code);
      if (err?.response?.data?.code === 409) {
        toast("User With This Email Already Exist");
        setIsLoading(false);
        document.getElementById("myInputU1").value = "";
        document.getElementById("myInputU2").value = "";
        document.getElementById("myInputU3").value = "";
        document.getElementById("myInputU4").value = "";
        document.getElementById("myInputU5").value = "";
        document.getElementById("myInputU6").value = "";
        document.getElementById("myInputU7").value = "";
        document.getElementById("myInputU8").value = "";
      } else if (!err?.response) {
        setErrMsg("No Server Response");
        toast("No Server Response, please try later");
        setIsLoading(false);
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
        console.log(errMsg);
        setIsLoading(false);
      } else if (err.response?.status === 400) {
        // setErrMsg("unsuccessful, check your input");
        toast("unsuccessful, check your input");
        setIsLoading(false);
      } else if (err.response?.status === 500) {
        toast("unsuccessful, please enter vaid details");
        setIsLoading(false);
      } else {
        toast("Failed to create, enter the correct infos");
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="newTrip">
      <Sidebar />
      <div className="newContainer">
        <Navbar />

        <div style={{ marginTop: "0rem" }} className="bottom">
          <form className="newForm">
            {Object.keys(errors).length !== 0 && (
              <div className="errorMsgWrapper">
                {errors["firstName"] && (
                  <div className="errorMsg">{errors["firstName"]}</div>
                )}
                {errors["lastName"] && (
                  <div className="errorMsg">{errors["lastName"]}</div>
                )}
                {errors["dialCode"] && (
                  <div className="errorMsg">{errors["dialCode"]}</div>
                )}
                {errors["phoneNumber"] && (
                  <div className="errorMsg">{errors["phoneNumber"]}</div>
                )}
                {errors["email"] && (
                  <div className="errorMsg">{errors["email"]}</div>
                )}
                {errors["gender"] && (
                  <div className="errorMsg">{errors["gender"]}</div>
                )}
              </div>
            )}
            <div className="form-group" style={{ margin: "1rem 0" }}>
              <div className="input__wrapper" style={{ width: "50%" }}>
                <span>First Name*</span>
                <TextField
                  required
                  id="myInputU1"
                  fullWidth
                  // label="firstName"
                  name="firstName"
                  // id="fullWidth"
                  style={{ margin: "1rem 0" }}
                  onChange={handleChange}
                />
              </div>
              <div className="input__wrapper" style={{ width: "50%" }}>
                <span>Last Name*</span>
                <TextField
                  required
                  fullWidth
                  id="myInputU2"
                  // label="lastName"
                  name="lastName"
                  // id="fullWidth"
                  style={{ margin: "1rem 0" }}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-group" style={{ margin: "1rem 0" }}>
              <div className="input__wrapper" style={{ width: "50%" }}>
                <span>Date Of Birth*</span>
                <TextField
                  fullWidth
                  id="myInputU3"
                  // label="Date Of Birth"
                  name="age"
                  type="date"
                  // id="fullWidth"
                  style={{ margin: "1rem 0" }}
                  onChange={handleChange}
                />
              </div>
              <div className="input__wrapper" style={{ width: "50%" }}>
                <span>Gender*</span>
                <select
                  style={{
                    height: "52px",
                    outline: "none",
                    margin: "1rem 0",
                    width: "100%",
                  }}
                  required
                  className="form-control profile-input"
                  name="gender"
                  onChange={handleChange}
                  id="myInputU4"
                >
                  <option>select...</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
              </div>
            </div>
            <div className="form-group" style={{ margin: "1rem 0" }}>
              <div className="input__wrapper" style={{ width: "50%" }}>
                <span>Email*</span>
                <TextField
                  required
                  fullWidth
                  type="email"
                  // label="email"
                  name="email"
                  // id="fullWidth"
                  id="myInputU5"
                  style={{ margin: "1rem 0" }}
                  onChange={handleChange}
                />{" "}
              </div>
              <div className="input__wrapper" style={{ width: "50%" }}>
                <span>Address*</span>
                <TextField
                  fullWidth
                  // label="address"
                  name="address"
                  // id="fullWidth"
                  id="myInputU6"
                  style={{ margin: "1rem 0" }}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="form-group" style={{ margin: "1rem 0" }}>
              <div className="input__wrapper" style={{ width: "50%" }}>
                <span>Country Code*</span>
                <TextField
                  required
                  fullWidth
                  // label="dial Code"
                  name="dialCode"
                  // id="fullWidth"
                  id="myInputU7"
                  style={{ margin: "1rem 0" }}
                  onChange={handleChange}
                />
              </div>
              <div className="input__wrapper" style={{ width: "50%" }}>
                <span>Phone Number*</span>
                <TextField
                  required
                  fullWidth
                  // label="Phone Number"
                  name="phoneNumber"
                  type="number"
                  // id="fullWidth"
                  id="myInputU8"
                  style={{ margin: "1rem 0" }}
                  onChange={handleChange}
                />
              </div>
            </div>
            <Button variant="contained" onClick={handleSubmit}>
              {isLoading ? "Loading" : "Submit"}
            </Button>
            <div style={{ color: "green", margin: "1.5rem" }}>
              <p>
                {message?.msg &&
                  "User has been successfully created. Login details have been emailed to the user"}
              </p>
              {message?.msg ? (
                ""
              ) : errMsg ? (
                <p style={{ color: "orangered" }}>{errMsg}</p>
              ) : (
                ""
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateNewUser01;

import React, { useState, useEffect } from "react";
import axios from "../../../API/axios";
import FlightDetailsTable from "../../table/tripTables/FlightDetailsTable";
import { toast } from "react-toastify";
const FLIGHT_DETAILS = "/trips/flight-details";

const FlightDetail = ({ _id, trip, setFetch }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [local, setLocal] = useState(null);
  const [errMsg, setErrMsg] = useState("");
  // const [datas, setDatas] = useState(trip[0]?.flightDetails);
  const [values, setValues] = useState({
    location: "",
    date: "",
  });
  const datas = trip[0]?.flightDetails;
  const [errors, setError] = useState({});

  const validateForm = () => {
    const { location, date } = values;
    let errors = {};
    let formIsValid = true;

    if (!location) {
      formIsValid = false;
      errors["location"] = "*Please enter location.";
    }
    if (!date) {
      formIsValid = false;
      errors["date"] = "*Please enter date.";
    }

    setError(errors);
    return formIsValid;
  };

  const editItem = (id) => {
    const idPos = datas?.findIndex((item) => item._id === id);
    if (idPos >= 0) {
      return setLocal(datas[idPos]);
    }
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  // const addFlightDetails = () => {
  //   return setDatas((prev) => [
  //     ...prev,
  //     {
  //       location: values.location,
  //       date: values.date,
  //     },
  //   ]);
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return toast.error("enter all input fields");
    }
    const { location, date } = values;
    const data = {
      _id: _id,
      location: location,
      date: date,
    };

    setIsLoading(true);

    try {
      const response = await axios.post(FLIGHT_DETAILS, data, {
        headers: {
          token: `${JSON.parse(localStorage.getItem("data22"))}`,
        },
      });
      setIsLoading(false);
      if (response?.data.msg) {
        toast(response?.data.msg);
        setLocal(null);
        setValues({
          location: "",
          date: "",
        });
        setFetch((prev) => !prev);
      }
    } catch (err) {
      // console.log(err?.response?.data?.code);
      if (err?.response?.data?.code === 409) {
        setErrMsg("User With This Email Already Exist");
        setIsLoading(false);
      } else if (!err?.response) {
        setErrMsg("No Server Response");
        setIsLoading(false);
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
        console.log(errMsg);
        setIsLoading(false);
      } else {
        setErrMsg("Failed");
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    setValues({
      location: local && local?.location,
      date: local && local?.date,
    });
  }, [local]);

  return (
    <div className="left">
      <div
        className="center"
        style={{ textAlign: "center", padding: "0.2rem 1rem" }}
      >
        <div style={{ position: "relative" }} className="center--segment mt-5">
          <h4 style={{ margin: "1rem" }}>Flight Details</h4>
          <form className="" onSubmit={handleSubmit}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                margin: "1rem 0",
              }}
              className="form-group"
            >
              <label className="profile-label">Location</label>
              <input
                id="myInput3"
                style={{ height: "40px", outline: "none", marginTop: "0.3rem" }}
                type="text"
                className="form-control profile-input"
                name="location"
                onChange={handleChange}
                defaultValue={values.location}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                margin: "1rem 0",
              }}
              className="form-group"
            >
              <label className="profile-label">Date</label>
              <input
                style={{ height: "40px", outline: "none", marginTop: "0.3rem" }}
                type="date"
                className="form-control profile-input"
                name="date"
                onChange={handleChange}
                defaultValue={values.date}
              />
            </div>
            {
              <button
                variant="contained"
                style={{
                  display: "block",
                  margin: "1rem",
                  border: "none",
                  padding: "0.5rem 1rem",
                  color: "white",
                  background: "blue",
                  borderRadius: "10px",
                  fontSize: "1.3rem",
                  cursor: "pointer",
                }}
              >
                {isLoading ? "saving..." : "save"}
              </button>
            }
          </form>
        </div>
      </div>
      <div className="ine">
        <FlightDetailsTable
          rows={datas}
          editItem={editItem}
          setFetch={setFetch}
          setLocal={setLocal}
          _id={_id}
          local={local}
          // deleteFromDatas={deleteFromDatas}
        />
      </div>
    </div>
  );
};

export default FlightDetail;

// setFetch={setFetch}
// setLocal1={setLocal1}

import React, { useState } from "react";
import { Link } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "../TableComponent.scss";
import "./OverViewTable.scss";
import moment from "moment";

const OverViewTable = ({ row }) => {
  const [showD, setShowD] = useState(false);
  // console.log(row);
  return (
    <TableContainer
      component={Paper}
      // style={{ margin: "0.5rem 2rem" }}
      className="table"
      sx={{ minHeight: 200, maxWidth: 500 }}
    >
      <Table sx={{ minWidth: 350 }} aria-label="simple table" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell className="tableCell" align="center">
              Title
            </TableCell>

            <TableCell className="tableCell" align="center">
              Start Date
            </TableCell>
            <TableCell className="tableCell" align="center">
              End Date
            </TableCell>
            {/* <TableCell className="tableCell" align="center">
              More
            </TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell className="tableCell" align="center">
              {row?.title}
            </TableCell>
            <TableCell className="tableCell" align="center">
              {row?.start_date && moment(row?.start_date).format("ll")}
            </TableCell>
            <TableCell className="tableCell" align="center">
              {/* {row?.end_date} */}
              {row?.end_date && moment(row?.end_date).format("ll")}
            </TableCell>
            {/* <TableCell className="tableCell" align="center">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                className="dropdownShow1"
                onClick={() => {
                  setShowD((prev) => !prev);
                }}
              >
                Action <ExpandMoreIcon />
                <div
                  id={`${!showD ? "show" : ""}`}
                  className="dropdownMenu1"
                  aria-labelledby="actions"
                >
                  <a className="dropdownItem" href="#">
                    Edit
                  </a>
                  <a className="dropdownItem" href="#">
                    Delete
                  </a>
                </div>
              </div>
            </TableCell> */}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OverViewTable;

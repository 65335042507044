import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import { toast } from "react-toastify";
import axios from "../../../API/axios";
const PAYMENT_DELETE = "/trips/payments";

export default function PaymentDelete({ local1, _id, setFetch, setLocal1 }) {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  // console.log(local1);
  // console.log(local1?._id);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const data = {
      _id: _id,
      payment_id: local1?._id,
    };
    try {
      const response = await axios.delete(PAYMENT_DELETE, {
        headers: {
          token: `${JSON.parse(localStorage.getItem("data22"))}`,
          "Content-Type": "application/json",
        },
        data,
      });
      setIsLoading(false);
      console.log(response?.data);
      // setSucces2((prev) => !prev);
      if (response?.data.msg) {
        toast(response?.data.msg);
        // setTitle("");
        setFetch((prev) => !prev);
        setLocal1(null);
      }
    } catch (err) {
      // console.log(err?.response?.data?.code);
      if (err?.response?.data?.code === 409) {
        setErrMsg("User With This Email Already Exist");
        setIsLoading(false);
      } else if (!err?.response) {
        setErrMsg("No Server Response");
        setIsLoading(false);
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
        console.log(errMsg);
        setIsLoading(false);
      } else {
        setErrMsg("Failed");
        setIsLoading(false);
      }
    }
  };

  return (
    <div>
      <button
        style={{
          border: "none",
          background: "none",
          fontSize: "1rem",
          cursor: "pointer",
        }}
        onClick={handleClickOpen}
      >
        Delete
      </button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Delete This Item</DialogTitle>
        <form className="" onSubmit={handleSubmit}>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this item?
            </DialogContentText>

            <TextField
              autoFocus
              name="title"
              margin="dense"
              // label="Document title"
              type="text"
              fullWidth
              variant="standard"
              value={local1?.title && local1?.title}
              // onChange={(e) => setTitle(e.target.value)}
            />
          </DialogContent>
          <button
            style={{
              color: "white",
              border: "none",
              background: "orangered",
              padding: "0.2rem 0.6rem",
              fontSize: "1.2rem",
              marginLeft: "1.5rem",
              cursor: "pointer",
              borderRadius: "5px",
            }}
          >
            {isLoading ? "Deleting..." : "Yes! Delete"}
          </button>
        </form>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

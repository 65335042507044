import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import axios from "../../../API/axios";
const PAYMENT_UPDATE = "/trips/payments";

export default function PaymentUpdate({
  local1,
  _id,
  setFetch,
  setLocal1,
  row,
}) {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [values, setValues] = useState({
    title: "",
    amount: null,
    pay_now_link: "",
    invoice_link: "",
    reciept_link: "",
    status: "",
  });

  console.log(local1);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { title, amount, pay_now_link, invoice_link, reciept_link, status } =
      values;
    const data = {
      _id: _id,
      payment_id: local1._id,
      payment_details: {
        title: title ? title : local1?.title,
        amount: amount ? amount : local1?.amount,
        pay_now_link: pay_now_link ? pay_now_link : local1?.pay_now_link,
        invoice_link: invoice_link ? invoice_link : local1?.invoice_link,
        reciept_link: reciept_link ? reciept_link : local1?.reciept_link,
        status: status ? status : local1?.status,
      },
    };
    console.log(data);
    setIsLoading(true);
    try {
      const response = await axios.patch(PAYMENT_UPDATE, data, {
        headers: {
          token: `${JSON.parse(localStorage.getItem("data22"))}`,
        },
      });
      setIsLoading(false);
      if (response?.data.msg) {
        toast(response?.data.msg);
        setLocal1(null);
        setFetch((prev) => !prev);
      }
    } catch (err) {
      // console.log(err?.response?.data?.code);
      if (err?.response?.data?.code === 409) {
        setErrMsg("Failed");
        setIsLoading(false);
      } else if (!err?.response) {
        setErrMsg("No Server Response");
        setIsLoading(false);
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
        console.log(errMsg);
        setIsLoading(false);
      } else {
        setErrMsg("Failed");
        setIsLoading(false);
      }
    }
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  return (
    <div>
      <button
        style={{
          border: "none",
          background: "none",
          fontSize: "1rem",
          cursor: "pointer",
        }}
        onClick={handleClickOpen}
      >
        Edit
      </button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Update This Item</DialogTitle>
        <form className="" onSubmit={handleSubmit}>
          <DialogContent>
            <DialogContentText>
              You can only change the title, else if you want to change the
              document/image you can delete and resend again
            </DialogContentText>

            <TextField
              autoFocus
              name="title"
              margin="dense"
              label="Title"
              type="text"
              fullWidth
              variant="standard"
              defaultValue={local1 ? local1?.title : values.title}
              onChange={handleChange}
            />
            <TextField
              autoFocus
              name="amount"
              margin="dense"
              label="Amount"
              type="text"
              fullWidth
              variant="standard"
              defaultValue={local1 ? local1?.amount : local1?.amount}
              onChange={handleChange}
            />
            <TextField
              autoFocus
              name="pay_now_link"
              margin="dense"
              label="Payment Link"
              type="text"
              fullWidth
              variant="standard"
              defaultValue={
                local1 ? local1?.pay_now_link : local1?.pay_now_link
              }
              onChange={handleChange}
            />
            <TextField
              autoFocus
              name="invoice_link"
              margin="dense"
              label="Invoice Link"
              type="text"
              fullWidth
              variant="standard"
              defaultValue={
                local1 ? local1?.invoice_link : local1?.invoice_link
              }
              onChange={handleChange}
            />
            <TextField
              autoFocus
              name="reciept_link"
              margin="dense"
              label="Receipt Link"
              type="text"
              fullWidth
              variant="standard"
              defaultValue={
                local1 ? local1?.reciept_link : local1?.reciept_link
              }
              onChange={handleChange}
            />
            <select
              style={{ height: "40px", outline: "none", marginTop: "0.3rem" }}
              className="form-control profile-input"
              value={values.status}
              name="status"
              onChange={handleChange}
            >
              <option value="Not Paid">Not Paid</option>
              <option value="Paid">Paid</option>
            </select>
          </DialogContent>
          <button
            style={{
              color: "white",
              border: "none",
              background: "#1976d2",
              padding: "0.2rem 0.6rem",
              fontSize: "1.2rem",
              marginLeft: "1.5rem",
              cursor: "pointer",
              borderRadius: "5px",
              // pointerEvents: !trueh && "none",
            }}
          >
            {isLoading ? "updating.." : "Save Update"}
          </button>
        </form>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

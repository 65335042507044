import React, { useContext, useEffect, useState } from "react";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import "./Single.scss";
import { MenuContext } from "../../context/MenuContext";
import { Link, useParams, useNavigate } from "react-router-dom";
import TableComponentSingleUser from "../../components/table/TableComponentSingleUser";
import Widgets from "../../components/widgetSingle/Widget";
import TKEServices from "../../components/widgetSingle/TKEServices";
import ProfilePic from "../../img/setupPage2.png";
import axios from "../../API/axios";
import UserBanner from "../../components/UserBanner";
const USER_DETAILS_ID = "/admin/users?email=";
const TRIP_CREATE = "/trips/create";
const FETCH_TRIP = "/trips/get-all?email=";

const Single = () => {
  const navigate = useNavigate();
  const { emailId } = useParams();
  const [fetch, setFetch] = useState(false);
  console.log(fetch);
  const { setUserToCreate } = useContext(MenuContext);
  const [isLoading1, setIsLoading1] = useState(false);
  const [singleUser, setSingleUser] = useState({});
  const [singleUserTrips, setSingleUserTrips] = useState([]);
  const [errMsg, setErrMsg] = useState("");
  const [userBana, setUserBana] = useState("");
  const [userMem, setuserMem] = useState("");
  // console.log(emailId);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading1(true);

    try {
      const response = await axios.post(
        TRIP_CREATE,
        {
          user: emailId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            token: `${JSON.parse(localStorage.getItem("data22"))}`,
          },
        }
      );
      setIsLoading1(false);

      const { _id } = response?.data?.data;
      // console.log(_id);
      navigate(`/trip/${_id}`);
      // setSucces2((prev) => !prev);
    } catch (err) {
      // console.log(err?.response?.data?.code);
      if (err?.response?.data?.code === 409) {
        setErrMsg("User With This Email Already Exist");
        setIsLoading1(false);
      } else if (!err?.response) {
        setErrMsg("No Server Response");
        setIsLoading1(false);
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
        console.log(errMsg);
        setIsLoading1(false);
      } else {
        setErrMsg("Login Failed");
        setIsLoading1(false);
      }
    }
  };

  const fetchUserByEmailId = async () => {
    try {
      const response = await axios.get(`${USER_DETAILS_ID}${emailId}`, {
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${JSON.parse(localStorage.getItem("data"))}`,
          token: `${JSON.parse(localStorage.getItem("data22"))}`,
        },
      });
      setSingleUser(response?.data?.data);
      // console.log(response?.data?.data);
      setUserBana(response?.data?.data?.banner?.link);
      setuserMem(response?.data?.data?.membership);
      const { data } = response?.data;
      // setEmail(data);
    } catch (err) {
      console.log(err.response);
    }
  };

  const fetchTrips = async () => {
    setIsLoading1(true);
    try {
      const response = await axios.get(
        `/trips/get-all?email=${emailId}`,
        // {
        //   email: emailId,
        // },
        {
          headers: {
            "Content-Type": "application/json",
            token: `${JSON.parse(localStorage.getItem("data22"))}`,
          },
        }
      );
      const { data } = response?.data;
      // setSingleUserTrips(data);
      setSingleUserTrips(data.reverse());
      setIsLoading1(false);
      // console.log(topReferrals);
    } catch (err) {
      console.log(err.response);
    }
  };

  useEffect(() => {
    fetchTrips();
    fetchUserByEmailId();
  }, [emailId, fetch]);

  // Setting count
  function isDateInFuture(date) {
    return new Date(date).getTime() < new Date().getTime();
  }
  const upComingTrip = singleUserTrips?.filter((item) => {
    if (!isDateInFuture(item?.overview?.start_date)) {
      return item;
    }
    return;
  });

  const newUpComing = upComingTrip?.sort(
    (a, b) =>
      new Date(a?.overview?.start_date) - new Date(b?.overview?.start_date)
  );

  const olderTrips = singleUserTrips?.filter((item) => {
    if (isDateInFuture(item?.overview?.start_date)) {
      return item;
    }
    return;
  });

  const trips = [...newUpComing, ...olderTrips];



  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        <div className="widgets2">
          <Widgets tripCount={upComingTrip.length} type="Upcoming Trip" />
          <Widgets tripCount1={olderTrips.length} type="Total Trip" />
          <TKEServices type="TKE Service" singleUser={singleUser} />
          {/* <Widgets type="Trip Requests (Users)" /> */}
        </div>
        <div className="top">
          <div className="left">
            <div
              className="editButton"
              onClick={(e) => {
                e.preventDefault();
                navigate("/EditUser");
                setUserToCreate(singleUser);
              }}
            >
              Edit
            </div>
            <h1 className="title">User Information</h1>
            <div className="item">
              <img
                src={ProfilePic}
                // classNameitemImg
                alt=""
                className="itemImage"
              />
              <div className="details">
                <h1 className="itemTitle">
                  {singleUser.firstName} {singleUser.lastName}
                </h1>
                <div className="detailItem">
                  <span className="itemKey">Email</span>
                  <div className="itemKey">{singleUser.email}</div>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Phone</span>
                  <div className="itemKey">{singleUser.phoneNumber}</div>
                </div>
                <div className="detailItem">&nbsp;</div>
              </div>
            </div>
          </div>

          <div className="right" style={{ padding: "2rem" }}>
            <UserBanner userBana={userBana} emailId={emailId} />
          </div>
        </div>
        <div className="bottom">
          <div className="tableTop">
            <h1 className="title">total Trips</h1>
            {/* <Link to={`/trip/${newrows[0].id}`}>Add New Trip</Link> */}
            <form onSubmit={handleSubmit}>
              <button
                style={{
                  display: "block",
                  margin: "1rem",
                  border: "none",
                  padding: "0.5rem 1rem",
                  color: "white",
                  background: "blue",
                  borderRadius: "10px",
                  fontSize: "1.3rem",
                  cursor: "pointer",
                }}
              >
                {isLoading1 ? "creating trip..." : " Add New Trip"}
              </button>
            </form>
          </div>
          <TableComponentSingleUser
            rows={trips}
            setIsLoading1={isLoading1}
            setFetch={setFetch}
          />
        </div>
      </div>
    </div>
  );
};

export default Single;

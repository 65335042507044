import React, { useContext, useState } from "react";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import "./SingleTrip.scss";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import axios from "../../API/axios";
import { Stack, List, ListItem, Divider } from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import DeleteIcon from "@mui/icons-material/Delete";
import { MenuContext } from "../../context/MenuContext";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
// import axios from "axios";
const CREATE_ADMIN = "/admin/create";

const CreateAdmin = () => {
  const { adminUsers, setAdminUsers, setAdminId } = useContext(MenuContext);
  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = useState({
    loginId: "",
    password: "",
  });
  const [errMsg, setErrMsg] = useState("");

  const navigate = useNavigate();

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const { loginId, password } = values;

    try {
      const response = await axios.post(
        CREATE_ADMIN,
        { loginId, password },
        {
          headers: {
            "Content-Type": "application/json",
            token: `${JSON.parse(localStorage.getItem("data22"))}`,
          },
        }
      );
      setErrMsg(response?.data?.msg);
      setIsLoading(false);
      // navigate("/");
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
        setIsLoading(false);
      } else if (err.response?.status === 400) {
        setErrMsg("Login Id or Password");
        setIsLoading(false);
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
        console.log(errMsg);
        setIsLoading(false);
      } else {
        setErrMsg("Login Failed");
        setIsLoading(false);
      }
    }
  };

  // const removefromArray = (item) => (myData = myData.splice(item, 1));

  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        <div className="top">
          <div className="left">
            <h1 className="title">Create Admin</h1>
            <div className="item">
              <div className="details">
                <form className="newForm">
                  <TextField
                    fullWidth
                    label="Login Id"
                    name="loginId"
                    id="fullWidth"
                    style={{ margin: "1rem 0" }}
                    onChange={handleChange}
                  />
                  <TextField
                    fullWidth
                    label="Password"
                    name="password"
                    id="fullWidth"
                    style={{ margin: "1rem 0" }}
                    onChange={handleChange}
                  />
                  <Button variant="contained" onClick={handleSubmit}>
                    {isLoading ? "Loading" : "Submit"}
                  </Button>
                </form>

                <p style={{ color: "green" }}>{errMsg}</p>
              </div>
            </div>
          </div>
          <div
            className="right"
            style={{
              padding: "1rem",
              // visibility: "hidden"
            }}
          >
            <h4>List Of Admin</h4>
            <Stack>
              <Stack>
                {Array.isArray(adminUsers) &&
                  adminUsers?.map((item, index) => {
                    const handleDelete = async (e) => {
                      e.preventDefault();

                      // setIsLoading(true);
                      try {
                        const response = await axios.delete(
                          "https://tke-travel.onrender.com/api/v1/admin/delete-other-admins",
                          {
                            headers: {
                              "Content-Type": "application/json",
                              token: JSON.parse(localStorage.getItem("data22")),
                            },
                            data: {
                              name: item?.name,
                            },
                          }
                        );
                        if (response?.data?.msg) {
                          toast(response?.data?.msg);
                        }
                        localStorage.setItem(
                          "adminUsers",
                          JSON.stringify(
                            JSON.parse(
                              localStorage.getItem("adminUsers")
                            ).filter((user) => user.name !== item.name)
                          )
                        );

                        const updatedUsers = adminUsers.filter(
                          (user) => user.name !== item.name
                        );
                        setAdminUsers(updatedUsers);

                        // removefromArray(index);
                        // setSucces((prev) => !prev);
                        // setIsLoading(false);
                      } catch (err) {
                        if (!err?.response) {
                          toast.error("No Server Response");
                          // setIsLoading(false);
                        } else {
                          toast.error("Request Does not Exist");
                          // setIsLoading(false);
                        }
                      }
                    };
                    return (
                      <List key={index}>
                        <Divider />
                        <ListItem>
                          <ListItemText
                            onClick={() => {
                              setAdminId(item.name);
                              navigate("/ResetOtherAdmin");
                            }}
                            sx={{
                              cursor: "pointer",
                              "&:hover": {
                                color: "blue",
                              },
                            }}
                            primary={item.name}
                          />
                          <DeleteIcon
                            onClick={handleDelete}
                            sx={{ color: "red", cursor: "pointer" }}
                          />
                        </ListItem>
                      </List>
                    );
                  })}
              </Stack>
            </Stack>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateAdmin;

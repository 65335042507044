import React from "react";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";

const SendReceipt = () => {
  return (
    <div className="newTrip">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div style={{ margin: "2rem" }}>SendReceipt</div>
      </div>
    </div>
  );
};

export default SendReceipt;

import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import { toast } from "react-toastify";
import axios from "../../API/axios";
const TRAVEL_CONFIRMATION_UPDATE = "/trips/travel-confirmation/update";

export default function TravelConfirmationModal({
  local,
  _id,
  setFetch,
  setLocal,
  row,
}) {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [title, setTitle] = useState("");
  const [datas20, setDatas20] = useState(row);
  const [trueh, setTrueh] = useState(false);
  console.log(datas20);
  const updateDatas = () => {
    const idPos = row?.findIndex((item) => item._id === local._id);
    if (idPos >= 0) {
      datas20[idPos] = {
        ...datas20[idPos],
        title: title,
      };
      toast("Changes Made, You Can Save!");
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await axios.post(
        TRAVEL_CONFIRMATION_UPDATE,
        {
          _id: _id,
          documents: datas20,
        },
        {
          headers: {
            token: `${JSON.parse(localStorage.getItem("data22"))}`,
          },
        }
      );
      setIsLoading(false);
      // (response?.data.msg);
      // setSucces2((prev) => !prev);
      if (response?.data.msg) {
        toast(response?.data.msg);
        setTitle("");
        setFetch((prev) => !prev);
        setLocal(null);
      }
    } catch (err) {
      // console.log(err?.response?.data?.code);
      if (err?.response?.data?.code === 409) {
        setErrMsg("User With This Email Already Exist");
        setIsLoading(false);
      } else if (!err?.response) {
        setErrMsg("No Server Response");
        setIsLoading(false);
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
        console.log(errMsg);
        setIsLoading(false);
      } else {
        setErrMsg("Failed");
        setIsLoading(false);
      }
    }
  };

  return (
    <div>
      <button
        style={{
          border: "none",
          background: "none",
          fontSize: "1rem",
          cursor: "pointer",
        }}
        onClick={handleClickOpen}
      >
        Edit
      </button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Update This Item</DialogTitle>
        <form className="" onSubmit={handleSubmit}>
          <DialogContent>
            <DialogContentText>
              You can only change the title, else if you want to change the
              document/image you can delete and resend again
            </DialogContentText>

            <TextField
              autoFocus
              name="title"
              margin="dense"
              label="Document title"
              type="text"
              fullWidth
              variant="standard"
              defaultValue={local ? local?.title : title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </DialogContent>
          <button
            style={{
              color: "white",
              border: "none",
              // background: "#1976d2",
              padding: "0.2rem 0.6rem",
              fontSize: "1.2rem",
              marginLeft: "1.5rem",
              cursor: "pointer",
              borderRadius: "5px",
              background: trueh ? "#1976d2" : "",
              pointerEvents: !trueh && "none",
            }}
          >
            {isLoading ? "updating.." : "Save Update"}
          </button>
        </form>
        <button
          style={{
            color: "white",
            border: "none",
            background: !trueh ? "#1976d2" : "",
            padding: "0.2rem 0.6rem",
            fontSize: "1.2rem",
            margin: "1.5rem auto",
            cursor: "pointer",
            borderRadius: "5px",
            width: "12%",
            textAlign: "center",
          }}
          onClick={() => {
            updateDatas();
            setTrueh(true);
          }}
        >
          Edit
        </button>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

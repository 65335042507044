import React, { useState, useContext, useEffect } from "react";
import "./TravelConfirmation.scss";
import axios from "../../../API/axios";
import TravelConfirmTable from "../../table/tripTables/TravelConfirmTable";
import { toast } from "react-toastify";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { Button } from "@mui/material";
const TRAVEL_CONFIRMATION = "/trips/travel-confirmation/add";

const TravelConfirmation = ({ _id, trip, setFetch }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [local, setLocal] = useState(null);
  // const [datas, setDatas] = useState(trip[0]?.travelConfirmation);
  // const [image, setImage] = useState(null);
  const [title, setTitle] = useState("");
  const [link, setLink] = useState("");
  const datas = trip[0]?.travelConfirmation;

  const validateForm = () => {
    let formIsValid = true;

    if (!title) {
      formIsValid = false;
    }
    if (!link) {
      formIsValid = false;
    }

    return formIsValid;
  };

  const editItem = (id) => {
    const idPos = datas?.findIndex((item) => item._id === id);
    if (idPos >= 0) {
      return setLocal(datas[idPos]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return toast.error("enter all input fields");
    }
    // const formData = new FormData();
    // formData.append("_id", _id);
    // // formData.append("document", image);
    // formData.append("document", link);
    // formData.append("title", title);

    // console.log([...formData]);

    const data = {
      _id: _id,
      title: title,
      document_link: link,
    };

    setIsLoading(true);

    try {
      const response = await axios.post(TRAVEL_CONFIRMATION, data, {
        headers: {
            "Content-Type": "application/json",
          token: `${JSON.parse(localStorage.getItem("data22"))}`,
        },
      });
      setIsLoading(false);
      // setLocal(response?.data?.data?.travelConfirmation);
      // setSucces2((prev) => !prev);
      if (response?.data.msg) {
        toast(response?.data.msg);
        setLocal(null);

        document.getElementById("myInput4").value = "";
        document.getElementById("myInput5").value = "";
        setFetch((prev) => !prev);
      }
    } catch (err) {
      // console.log(err?.response?.data?.code);
      if (!err?.response) {
        setErrMsg("No Server Response");
        setIsLoading(false);
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
        console.log(errMsg);
        setIsLoading(false);
      } else if (err.response?.status === 500) {
        setErrMsg("");
        toast.error("File too large, image should not be more than 2mb");
        console.log(errMsg);
        setIsLoading(false);
      } else {
        setErrMsg("Login Failed");
        setIsLoading(false);
      }
    }
  };
  // const handleImage = (event) => {
  //   setImage(event.target.files[0]);
  // };

  return (
    <div className="left">
      <div
        className="center center1"
        style={{
          textAlign: "center",
          padding: "0.2rem 1rem",
          position: "relative",
          height: "300px",
        }}
      >
        <h4 style={{ margin: "1rem" }}>Travel Confirmation</h4>
        <form className="" onSubmit={handleSubmit}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              margin: "1rem 0",
            }}
            className="form-group"
          >
            <label className="profile-label">Document Title</label>
            <input
              id="myInput4"
              style={{ height: "40px", outline: "none", marginTop: "0.3rem" }}
              type="text"
              className="form-control profile-input"
              name="title"
              onChange={(e) => setTitle(e.target.value)}
              // defaultValue={title}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              margin: "1rem 0",
            }}
            className="form-group"
          >
            <label className="profile-label">Document Link</label>
            <input
              id="myInput5"
              style={{ height: "40px", outline: "none", marginTop: "0.3rem" }}
              type="text"
              className="form-control profile-input"
              name="link"
              onChange={(e) => setLink(e.target.value)}
              // defaultValue={title}
            />
          </div>

          {/* <Button
            variant=""
            component="label"
            style={{
              margin: "1rem 0",
              border: "solid 1px gray",
              padding: "0.4rem 1rem",
              fontSize: "1rem",
            }}
          >
            <FileUploadIcon /> Upload Document
            <input
              type="file"
              name="image"
              accept="application/pdf,image/*"
              onChange={handleImage}
              hidden
            />
          </Button>
          <span style={{ margin: "1rem", color: "grey ", fontWeight: "bold" }}>
            {image?.name}
          </span> */}
          <button
            variant="contained"
            style={{
              display: "block",
              margin: "1rem",
              border: "none",
              padding: "0.5rem 1rem",
              color: "white",
              background: "blue",
              borderRadius: "10px",
              fontSize: "1.3rem",
              cursor: "pointer",
            }}
          >
            {isLoading ? "saving..." : "save"}
          </button>
        </form>
      </div>
      <div className="ine">
        <TravelConfirmTable
          row={datas}
          editItem={editItem}
          local={local}
          _id={_id}
          setFetch={setFetch}
          setLocal={setLocal}
        />
      </div>
    </div>
  );
};

export default TravelConfirmation;

{
  /* <TravelConfirmTable row={local ? local : trip[0]?.travelConfirmation} /> */
}

import React, { useContext, useEffect, useState } from "react";
import Chart from "../../components/chart/Chart";
import ChartTrips from "../../components/chart/ChartTrips";
// import Featured from "../../components/featured/Featured";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
// import TableComponent from "../../components/table/TableComponent";
import TableComponentDashboard from "../../components/table/TableComponentDashboard";
import Widgets from "../../components/widget/Widget";
import { MenuContext } from "../../context/MenuContext";
import ReactPaginate from "react-paginate";
import "./Home.scss";
import DataTables from "../../components/datatables/DataTables";

const Home = () => {
  const {
    newTripReq,
    userTripReq,
    prospectTripReq,
    monthlyTripReq,
    tripAll,
    succes2,
    fetchTrips,
  } = useContext(MenuContext);

  //PAGINATE
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 5;

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(newTripReq?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(newTripReq?.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, newTripReq]);

  //

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % newTripReq?.length;
    setItemOffset(newOffset);
  };

  useEffect(() => {
    fetchTrips();
  }, [succes2]);

  useEffect(() => {
    localStorage.setItem("tripAll", JSON.stringify(tripAll));
  }, [tripAll]);

  return (
    <div className="home">
      <Sidebar />
      <div className="homeContainer">
        <Navbar />
        <div className="widgets">
          <Widgets type="Trips" data100={tripAll?.trips} />
          <Widgets type="New Trip Requests" data1={newTripReq} />
          <Widgets type="Trip Requests (Prospects)" data2={prospectTripReq} />
          <Widgets type="Trip Requests (Users)" data3={userTripReq} />
        </div>
        <div className="charts">
          {/* <Featured /> */}
          <Chart
            title="Trips"
            aspect={2 / 1}
            data1={tripAll.tripsMonthlyCount}
          />
          <ChartTrips
            title="Trip Requests"
            aspect={2 / 1}
            data4={monthlyTripReq}
          />
        </div>
        <div className="listContainer">
          <div className="listTitle">New Trip Requests</div>
          <TableComponentDashboard rows={newTripReq} />
        </div>
        {/* <div style={{ margin: "2rem 3rem" }}>
          <ReactPaginate
            breakLabel="..."
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="< previous"
            renderOnZeroPageCount={null}
            // styling the whole btns, note: always know when to use thw class with link a tags and without for just li tags
            containerClassName="pagination1"
            pageLinkClassName="page-num"
            previousLinkClassName="page-num"
            nextLinkClassName="page-num"
            activeLinkClassName="active"
          />
        </div> */}
      </div>
    </div>
  );
};

export default Home;

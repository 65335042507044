import React, { useState, useEffect } from "react";
import axios from "../../../API/axios";
// import RefreshIcon from "@mui/icons-material/Refresh";

const TripReview = ({ _id }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [trip, setTrip] = useState([]);

  const fetchTrips = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`/trips/get-all?_id=${_id}`, {
        headers: {
          "Content-Type": "application/json",
          token: `${JSON.parse(localStorage.getItem("data22"))}`,
        },
      });
      const { data } = response?.data;
      setTrip(data);
      // console.log(data);
      setIsLoading(false);
      // console.log(topReferrals);
    } catch (err) {
      console.log(err.response);
    }
  };

  useEffect(() => {
    fetchTrips();
  }, []);

  // console.log(trip[0]?.tripReview);

  return (
    <div
      className="left"
      style={{
        maxWidth: "800px",
      }}
    >
      <div
        className="center center1"
        style={{
          padding: "1rem",
          textAlign: "center",
        }}
      >
        {/* <button
          style={{
            margin: "0.4rem",
            border: "none",
            padding: "0.2rem 0.6rem ",
            boxShadow: "2px 4px 10px 1px rgba(0,0, 0,.3)",
            fontSize: "1rem",
            fontWeight: "bold",
            color: "blue",
            cursor: "pointer",
          }}
          onClick={fetchTrips}
        >
          <RefreshIcon />
        </button> */}
        {!trip[0]?.tripReview && (
          <p style={{ margin: "4rem" }}>No Trip Review Yet...</p>
        )}
        <div style={{ margin: "1rem", width: "100%" }} className="">
          {trip[0]?.tripReview && (
            <div style={{ width: "100%", display: "flex", flexWrap: "wrap" }}>
              {trip[0]?.tripReview?.image?.map((item) => (
                <div
                  style={{
                    maxWidth: "40%",
                    height: "auto",
                    margin: "20px 15px",
                    boxShadow: "2px 4px 10px 1px rgba(0,0,0,.3)",
                  }}
                >
                  <a href={item?.link} target="_blank">
                    {" "}
                    <img
                      style={{ maxWidth: "90%", height: "auto" }}
                      src={item?.link}
                      alt=""
                    />
                  </a>
                </div>
              ))}
            </div>
          )}
        </div>
        <div
          style={{
            wordWrap: "break-word",
            padding: "15px 10px",
          }}
        >
          <p>{trip[0]?.tripReview?.body}</p>
        </div>
      </div>
    </div>
  );
};

export default TripReview;

import React, { useState, useEffect, useContext } from "react";
import { MenuContext } from "../../../context/MenuContext";
import { TextField, Button } from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import OverViewTable from "../../table/tripTables/OverViewTable";
import "./OverView.scss";
import axios from "../../../API/axios";
import { toast } from "react-toastify";
const POST_OVER_VIEW = "/trips/overview";

const OverView = ({ _id, trip }) => {
  const { setSucces2 } = useContext(MenuContext);
  const [isLoading, setIsLoading] = useState(false);
  const [description, setDescription] = useState(
    trip[0]?.overview?.description ? trip[0]?.overview?.description : ""
  );
  const [title, setTitle] = useState(
    trip[0]?.overview?.title ? trip[0]?.overview?.title : ""
  );
  const [image, setImage] = useState(null);
  const [start_date, setStart_date] = useState(
    trip[0]?.overview?.start_date ? trip[0]?.overview?.start_date : null
  );
  const [end_date, setEnd_date] = useState(
    trip[0]?.overview?.end_date ? trip[0]?.overview?.end_date : null
  );
  const [local, setLocal] = useState(null);
  // console.log(trip[0]?.overview?.start_date);
  const imgSizing = () => {
    if (image?.size > 2000000) {
      return toast.error("file size is too large, image should not exceed 2mb");
    }
  };

  const validateForm = () => {
    let formIsValid = true;

    if (!title) {
      formIsValid = false;
    }
    if (!start_date) {
      formIsValid = false;
    }
    if (!end_date) {
      formIsValid = false;
    }
    // if (!image) {
    //   formIsValid = false;
    // }

    return formIsValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return toast.error("enter all input fields");
    }

    if (imgSizing()) {
      return;
    }

    const formData = new FormData();
    formData.append("title", title);
    formData.append("image", image);
    formData.append("description", description);
    formData.append("start_date", start_date);
    formData.append("end_date", end_date);
    formData.append("_id", _id);

    // console.log([...formData]);
    setIsLoading(true);
    try {
      const response = await axios.post(POST_OVER_VIEW, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          token: `${JSON.parse(localStorage.getItem("data22"))}`,
        },
      });
      setIsLoading(false);
      setLocal(response?.data?.data?.overview);
      console.log(response?.data?.data?.overview);
      setSucces2((prev) => !prev);
      if (response?.data.msg) {
        toast(response?.data.msg);
        // console.log(response?.data.msg);
      }
    } catch (error) {
      if (!error?.response) {
        toast.error("No Server Response");
        setIsLoading(false);
      } else if (error.response?.status === 500) {
        // setErrMsg("");
        toast.error("File too large, image should not be more than 2mb");
        setIsLoading(false);
      }
    }
  };

  const handleImage = (event) => {
    setImage(event.target.files[0]);
  };

  return (
    <div className="left">
      <div
        className="center"
        style={{ textAlign: "center", padding: "0.2rem 1rem" }}
      >
        <div className="center--segment">
          <h4 style={{ margin: "1rem" }}>User Trip Overview</h4>
          <form className="" onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label="Destination Title"
              name="title"
              id="fullWidth"
              style={{ margin: "1rem 0" }}
              onChange={(e) => setTitle(e.target.value)}
              defaultValue={title}
            />
            <TextField
              fullWidth
              label="Description"
              name="description"
              id="fullWidth"
              style={{ margin: "1rem 0" }}
              multiline
              rows={2}
              // maxRows={4}
              onChange={(e) => setDescription(e.target.value)}
              defaultValue={description}
            />
            <div style={{ margin: "1rem 0" }}>
              <label htmlFor="">Start Date</label>
              <TextField
                fullWidth
                type="date"
                name="start_date"
                id="fullWidth"
                style={{ margin: "1rem 0rem" }}
                onChange={(e) => setStart_date(e.target.value)}
                defaultValue={start_date}
              />
            </div>
            <div style={{ margin: "1rem 0" }}>
              <label htmlFor="">End Date</label>
              <TextField
                fullWidth
                type="date"
                name="end_date"
                id="fullWidth"
                style={{ margin: "1rem 0" }}
                onChange={(e) => setEnd_date(e.target.value)}
                defaultValue={end_date}
              />
            </div>
            <span
              style={{
                fontSize: "0.8rem",
                marginRight: "1rem",
                marginLeft: "-0.8rem",
                color: "orangered",
              }}
            >
              {" "}
              *Max: 600 x 600*
            </span>
            <Button
              variant=""
              component="label"
              style={{ margin: "1rem 0", border: "solid 1px gray" }}
            >
              <FileUploadIcon /> Upload Image
              <input
                type="file"
                name="image"
                accept="image/*"
                hidden
                onChange={handleImage}
              />
            </Button>
            <p style={{ margin: "", color: "grey ", fontWeight: "bold" }}>
              {image?.name}
            </p>
            <button
              variant="contained"
              style={{
                display: "block",
                margin: "1rem",
                border: "none",
                padding: "0.5rem 1rem",
                color: "white",
                background: "blue",
                borderRadius: "10px",
                fontSize: "1.3rem",
                cursor: "pointer",
              }}
              id="nnt"
            >
              {isLoading ? "sending..." : "submit"}
            </button>
          </form>
        </div>
      </div>
      {/* <div className="left2"> */}
      <div className="ine">
        <OverViewTable row={local ? local : trip[0]?.overview} />
      </div>
      {/* </div> */}
    </div>
  );
};

export default OverView;

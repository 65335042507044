import React, { useContext, useEffect } from "react";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import "./List.scss";
import { MenuContext } from "../../context/MenuContext";
// import TableComponent from "../../components/table/TableComponent";
import TableComponentUsers from "../../components/table/TableComponentUsers";
import { useState } from "react";

const List = () => {
  const { users, isLoading, fetchUSers, succes } = useContext(MenuContext);
  const [query, setQuery] = useState("");

  const filteredItems = users?.filter((item) =>
    item?.firstName?.toLowerCase().includes(query?.toLowerCase())
  );
  useEffect(() => {
    fetchUSers();
  }, [succes]);

  useEffect(() => {
    localStorage.setItem("users", JSON.stringify(users)); // it set user base on 1st load and chnages made on users
  }, [users]);

  return (
    <div className="list user__list">
      <Sidebar />
      <div className="ListContainer">
        <Navbar />
        <div
          style={{
            width: "100%",
            height: "40px",
            padding: "1rem 2rem",
            margin: "1rem 0 2rem 0",
          }}
          className="top"
        >
          <input
            style={{ height: "35px", width: "300px", padding: "0.4rem 1rem" }}
            type="text"
            placeholder="search users..."
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>
        <div style={{ marginLeft: "1rem", marginTop: "3remx" }} className="">
          <h4 className="">Users</h4>
        </div>

        <TableComponentUsers rows={filteredItems} isLoading={isLoading} />
      </div>
    </div>
  );
};

export default List;

import React, { useContext } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "./TableComponent.scss";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "../../API/axios";
import DeleteIcon from "@mui/icons-material/Delete";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { MenuContext } from "../../context/MenuContext";
import { useState } from "react";

//
const TableComponentUsers = ({ rows, isLoading }) => {
  const { setSucces } = useContext(MenuContext);
  const [userDetails, setUserDetails] = useState({});
  const [modal, setModal] = useState(false);

  return (
    <>
      <TableContainer
        component={Paper}
        style={{ margin: "1rem", height: "600px" }}
        className="table"
        // sx={{ minHeight: 650 }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className="tableCell" align="center">
                #
              </TableCell>
              <TableCell className="tableCell" align="center">
                First Name
              </TableCell>
              <TableCell className="tableCell" align="center">
                Last Name
              </TableCell>
              <TableCell className="tableCell" align="center">
                Email
              </TableCell>
              <TableCell className="tableCell" align="center">
                Phone No:
              </TableCell>
              <TableCell className="tableCell" align="center">
                Delete
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length === 0 ? (
              <Box
                sx={{
                  display: "flex",
                  position: "relative",
                  top: "10rem",
                  left: "30rem",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              rows?.map((row, index) => {
                return (
                  <>
                    <UsersTable
                      key={index}
                      row={row}
                      index={index}
                      setSucces={setSucces}
                      // toggleModal={toggleModal}
                      setUserDetails={setUserDetails}
                      setModal={setModal}
                      modal={modal}
                      userDetails={userDetails}
                    />
                  </>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const UsersTable = ({
  row,
  index,
  setUserDetails,
  setSucces,
  setModal,
  userDetails,
  modal,
}) => {
  return (
    <TableRow key={index}>
      <TableCell className="tableCell" align="center">
        <div
          className={`${modal ? "display__modal show__btn" : "display__modal"}`}
        >
          <ModalComp
            row={row}
            index={index}
            setSucces={setSucces}
            setModal={setModal}
            userDetails={userDetails}
            modal={modal}
          />
        </div>
        {index + 1}
      </TableCell>
      <TableCell className="tableCell" align="center">
        <Link to={`/user/${row?.email}`} className="cellWrapper">
          {row?.firstName}
        </Link>
      </TableCell>
      <TableCell className="tableCell" align="center">
        {row?.lastName}
      </TableCell>
      <TableCell className="tableCell" align="center">
        {row?.email}
      </TableCell>
      <TableCell className="tableCell" align="center">
        {row?.phoneNumber}
      </TableCell>
      <TableCell className="tableCell" align="center">
        <DeleteIcon
          style={{ color: "orangered", cursor: "pointer" }}
          // onClick={toggleModal}
          onClick={() => {
            setUserDetails(row);
            setModal((prev) => !prev);
          }}
        />
      </TableCell>
    </TableRow>
  );
};

const ModalComp = ({ setSucces, setModal, userDetails, modal }) => {
  const handleDelete = async (e) => {
    e.preventDefault();
    const data = {
      email: userDetails?.email,
      deleteTrips: true,
    };
    // setIsLoading(true);
    try {
      const response = await axios.post(
        "https://tke-travel.onrender.com/api/v1/user/delete-user",
        data,
        {
          headers: {
            "Content-Type": "application/json",
            token: JSON.parse(localStorage.getItem("data22")),
          },
        }
      );
      if (response?.data?.msg) {
        toast(response?.data?.msg);
      }
      setSucces((prev) => !prev);
      // setIsLoading(false);
    } catch (err) {
      if (!err?.response) {
        toast.error("No Server Response");
        // setIsLoading(false);
      } else {
        toast.error("Request Does not Exist");
        // setIsLoading(false);
      }
    }
  };
  return (
    <div className="modal__body" onClick={() => setModal((prev) => !prev)}>
      <span className="btn_no1">X</span>
      <div className="body__cont">
        <h2>{userDetails?.firstName}</h2>
        <p>{userDetails?.email}</p>
      </div>
      <div>
        <p>Are you sure you want to delete this user?</p>
      </div>
      <div className="btn_modal">
        <button className="btn_yes" onClick={handleDelete}>
          Yes
        </button>
      </div>
    </div>
  );
};

export default TableComponentUsers;

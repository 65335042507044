import React, { useState, useContext, useEffect } from "react";
import { TextField, Button } from "@mui/material";
import "./Payment.scss";
// import FileUploadIcon from "@mui/icons-material/FileUpload";
import { MenuContext } from "../../../context/MenuContext";
import PaymentTable from "../../table/tripTables/PaymentTable";
import axios from "../../../API/axios";
import { toast } from "react-toastify";
const PAYMENT = "/trips/payments";

// const dataSaved = () => {
//   const localData = JSON.parse(localStorage.getItem("datas001"));
//   return localData ? localData : [];
// };
// const [datas, setDatas] = useState(
//   trip[0]?.payment ? trip[0]?.payment : dataSaved
// );
const Payment = ({ _id, trip, setFetch }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [local, setLocal] = useState(null);
  // const [datas, setDatas] = useState(trip[0]?.payment);
  const datas = trip[0]?.payment;
  const [values, setValues] = useState({
    title: "",
    amount: null,
    pay_now_link: "",
    invoice_link: "",
    reciept_link: "",
    status: "",
  });

  const validateForm = () => {
    const { title, amount, pay_now_link, invoice_link } = values;

    let formIsValid = true;

    if (!title) {
      formIsValid = false;
    }
    if (!amount) {
      formIsValid = false;
    }
    if (!pay_now_link) {
      formIsValid = false;
    }
    if (!invoice_link) {
      formIsValid = false;
    }

    return formIsValid;
  };

  const editItem = (id) => {
    const idPos = datas?.findIndex((item) => item._id === id);
    if (idPos >= 0) {
      return setLocal(datas[idPos]);
    }
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  // console.log(values);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return toast.error("enter all input fields");
    }

    const { title, amount, pay_now_link, invoice_link, reciept_link, status } =
      values;
    const data = {
      _id: _id,
      payment_details: {
        title: title,
        amount: amount,
        pay_now_link: pay_now_link,
        invoice_link: invoice_link,
        reciept_link: reciept_link,
        status: status ? status : "Not Paid",
      },
    };
    console.log(data);
    setIsLoading(true);
    // const { title, amount, pay_now_link, invoice_link } = values;

    try {
      const response = await axios.post(PAYMENT, data, {
        headers: {
          token: `${JSON.parse(localStorage.getItem("data22"))}`,
        },
      });
      setIsLoading(false);
      if (response?.data.msg) {
        toast(response?.data.msg);
        setLocal(null);
        setValues({
          title: "",
          amount: "",
          pay_now_link: "",
          invoice_link: "",
          reciept_link: "",
          status: "",
        });
        setFetch((prev) => !prev);
        // localStorage.setItem("datas001", JSON.stringify(datas));
      }
    } catch (err) {
      // console.log(err?.response?.data?.code);
      if (err?.response?.data?.code === 409) {
        setErrMsg("User With This Email Already Exist");
        setIsLoading(false);
      } else if (!err?.response) {
        setErrMsg("No Server Response");
        setIsLoading(false);
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
        console.log(errMsg);
        setIsLoading(false);
      } else {
        setErrMsg("Login Failed");
        setIsLoading(false);
      }
    }
  };

  console.log(trip);
  return (
    <div style={{ marginBottom: "5rem" }} className="left">
      <div
        className="center center1"
        style={{
          textAlign: "center",
          padding: "0.2rem 1rem",
          position: "relative",
        }}
      >
        <h4 style={{ margin: "1rem" }}>Payment</h4>
        <form className="" onSubmit={handleSubmit}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              margin: "1rem 0",
            }}
            className="form-group"
          >
            <label className="profile-label">Payment title</label>
            <input
              id="myInput5"
              style={{ height: "40px", outline: "none", marginTop: "0.3rem" }}
              type="text"
              className="form-control profile-input"
              name="title"
              onChange={handleChange}
              defaultValue={values.title}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              margin: "1rem 0",
            }}
            className="form-group"
          >
            <label className="profile-label">Amount</label>
            <input
              id="myInput6"
              style={{ height: "40px", outline: "none", marginTop: "0.3rem" }}
              type="text"
              className="form-control profile-input"
              name="amount"
              onChange={handleChange}
              defaultValue={values.amount}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              margin: "1rem 0",
            }}
            className="form-group"
          >
            <label className="profile-label">Payment Link</label>
            <input
              id="myInput7"
              style={{ height: "40px", outline: "none", marginTop: "0.3rem" }}
              type="text"
              className="form-control profile-input"
              name="pay_now_link"
              onChange={handleChange}
              defaultValue={values.pay_now_link}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              margin: "1rem 0",
            }}
            className="form-group"
          >
            <label className="profile-label">Invoice Link</label>
            <input
              id="myInput8"
              style={{ height: "40px", outline: "none", marginTop: "0.3rem" }}
              type="text"
              className="form-control profile-input"
              name="invoice_link"
              onChange={handleChange}
              defaultValue={values.invoice_link}
            />
          </div>
          {local && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                margin: "1rem 0",
              }}
              className="form-group"
            >
              <label className="profile-label">Receipt Link</label>
              <input
                style={{ height: "40px", outline: "none", marginTop: "0.3rem" }}
                type="text"
                className="form-control profile-input"
                name="reciept_link"
                onChange={handleChange}
                defaultValue={values.reciept_link}
              />
            </div>
          )}

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              margin: "1rem 0",
            }}
            className="form-group"
          >
            <label className="profile-label">Status</label>
            <select
              style={{ height: "40px", outline: "none", marginTop: "0.3rem" }}
              className="form-control profile-input"
              value={values.status}
              name="status"
              onChange={handleChange}
            >
              <option value="Not Paid">Not Paid</option>
              <option value="Paid">Paid</option>
            </select>
          </div>

          <button
            variant="contained"
            style={{
              display: "block",
              margin: "2rem 1rem 0rem 1rem",
              border: "none",
              padding: "0.5rem 1rem",
              color: "white",
              background: "blue",
              borderRadius: "10px",
              fontSize: "1.8rem",
              cursor: "pointer",
            }}
          >
            {isLoading ? "saving..." : "save"}
          </button>
        </form>
      </div>
      <div className="ine">
        <PaymentTable
          row={datas}
          editItem={editItem}
          setFetch={setFetch}
          setLocal={setLocal}
          _id={_id}
          local={local}
          // deleteFromDatas={deleteFromDatas}
        />
      </div>
    </div>
  );
};

export default Payment;

// const updateDatas = () => {
//   const idPos = datas?.findIndex((item) => item._id === local._id);
//   if (idPos >= 0) {
//     datas[idPos] = values;
//     toast("Changes Made, Please save!");
//   }
// };

// const deleteFromDatas = (id) => {
//   const idPos = datas?.findIndex((item) => item._id === id);
//   if (idPos >= 0) {
//     setDatas(datas.filter((item) => item._id !== id));
//     // datas[idPos] = values;
//     toast("Item deleted, Please save!");
//   }
// };

// const addFlightDetails = () => {
//   return setDatas((prev) => [
//     ...prev,
//     {
//       title: values.title,
//       amount: values.amount,
//       pay_now_link: values.pay_now_link,
//       invoice_link: values.invoice_link,
//       reciept_link: values.reciept_link,
//       status: values.status ? values.status : "Not Paid",
//     },
//   ]);
// };

import React, { useContext, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "./TableComponent.scss";
import { Link } from "react-router-dom";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";

import { MenuContext } from "../../context/MenuContext";
import { toast } from "react-toastify";
import axios from "axios";

const TableComponentSingleUser = ({ rows, isLoading1, setFetch }) => {
  const { setSucces } = useContext(MenuContext);
  const [userDetails, setUserDetails] = useState({});
  const [modal, setModal] = useState(false);
  return (
    <TableContainer
      component={Paper}
      style={{ margin: "1rem", height: 350 }}
      className="table"
      // sx={{ minHeight: 650 }}
    >
      <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell className="tableCell" align="center">
              #
            </TableCell>
            <TableCell className="tableCell" align="center">
              Trip Title
            </TableCell>
            <TableCell className="tableCell" align="center">
              Start Date
            </TableCell>
            <TableCell className="tableCell" align="center">
              End Date
            </TableCell>
            <TableCell className="tableCell" align="center">
              Delete
            </TableCell>
            {/* <TableCell className="tableCell" align="center">
              Action
            </TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.length === 0 ? (
            <Box
              sx={{
                display: "flex",
                position: "relative",
                top: "10rem",
                left: "30rem",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            rows?.map((row, index) => {
              return (
                <TableRow key={row?._id}>
                  <TableCell className="tableCell" align="center">
                    <span
                      className={`${
                        modal ? "display__modal show__btn" : "display__modal"
                      }`}
                    >
                      <ModalComp
                        row={row}
                        index={index}
                        setFetch={setFetch}
                        setModal={setModal}
                        userDetails={userDetails}
                        modal={modal}
                      />
                    </span>
                    {index + 1}
                  </TableCell>
                  <TableCell className="tableCell" align="center">
                    <Link
                      style={{ textDecoration: "none" }}
                      to={`/trip/${row?._id}`}
                    >
                      {row?.overview?.title ? row?.overview?.title : row?.user}
                      {/* {row?.overview?.title} */}
                    </Link>
                  </TableCell>
                  <TableCell className="tableCell" align="center">
                    <span className={`status`}>
                      {row?.overview?.start_date
                        ? moment(row?.overview?.start_date).format("ll")
                        : "22/09/2022"}
                    </span>
                  </TableCell>
                  <TableCell className="tableCell" align="center">
                    <span className={`status`}>
                      {row?.overview?.end_date
                        ? moment(row?.overview?.end_date).format("ll")
                        : "22/09/2022"}
                    </span>
                  </TableCell>
                  <TableCell className="tableCell" align="center">
                    <DeleteIcon
                      style={{ color: "orangered", cursor: "pointer" }}
                      onClick={() => {
                        setUserDetails(row);
                        setModal((prev) => !prev);
                      }}
                    />
                  </TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

//Modal

const ModalComp = ({ setFetch, setModal, userDetails, modal }) => {
  const handleDelete = async (e) => {
    e.preventDefault();
    const data = {
      id: userDetails?._id,
    };
    // setIsLoading(true);
    try {
      const response = await axios.delete(
        "https://tke-travel.onrender.com/api/v1/trips/delete",

        {
          headers: {
            "Content-Type": "application/json",
            token: JSON.parse(localStorage.getItem("data22")),
          },
          data,
        }
      );
      if (response?.data?.msg) {
        toast(response?.data?.msg);
      }
      setFetch((prev) => !prev);
      // setIsLoading(false);
    } catch (err) {
      if (!err?.response) {
        toast.error("No Server Response");
        // setIsLoading(false);
      } else {
        toast.error("Request Does not Exist");
        // setIsLoading(false);
      }
    }
  };
  console.log(userDetails?._id);
  return (
    <div className="modal__body" onClick={() => setModal((prev) => !prev)}>
      <span className="btn_no1">X</span>
      <div className="body__cont">
        <h2>{userDetails?.overview?.title}</h2>
      </div>
      <div>
        <p>Are you sure you want to delete this trip?</p>
      </div>
      <div className="btn_modal">
        <button className="btn_yes" onClick={handleDelete}>
          Yes
        </button>
      </div>
    </div>
  );
};

export default TableComponentSingleUser;

import React, { useState, useContext } from "react";
import { MenuContext } from "../../../context/MenuContext";
import { TextField, Button } from "@mui/material";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "./Itenary.scss";
import axios from "../../../API/axios";
import { toast } from "react-toastify";
const ITINENARY = "/trips/itinerary";

const Itenary = ({ _id, trip, setFetch }) => {
  const [body1, SetBody1] = useState("");

  const { setIsLoading, isLoading, setSucces2 } = useContext(MenuContext);
  const [errMsg, setErrMsg] = useState("");

  console.log(_id);
  console.log(body1);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await axios.post(
        ITINENARY,

        {
          _id: _id,
          itinerary: body1,
        },
        {
          headers: {
            "Content-Type": "application/json",
            token: `${JSON.parse(localStorage.getItem("data22"))}`,
          },
        }
      );
      setIsLoading(false);
      // console.log(response);
      setFetch((prev) => !prev);
      if (response?.data.msg) {
        toast(response?.data.msg);
        // console.log(response?.data.msg);
      }
    } catch (err) {
      // console.log(err?.response?.data?.code);
      if (err?.response?.data?.code === 409) {
        setErrMsg("Error");
        setIsLoading(false);
      } else if (!err?.response) {
        setErrMsg("No Server Response");
        setIsLoading(false);
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
        console.log(errMsg);
        setIsLoading(false);
      } else {
        setErrMsg("Failed");
        setIsLoading(false);
      }
    }
  };

  // const body = body1;
  return (
    <div className="left">
      <div
        className="center center1"
        style={{ textAlign: "center", padding: "0.2rem 1rem" }}
      >
        <h4 style={{ margin: "1rem" }}>Itinerary</h4>
        <form className="" onSubmit={handleSubmit}>
          {/* <Editor
            textareaName="Body"
            initialValue="<p>This is the initial content of the editor.</p>"
            init={{
              height: 500,
              menubar: false,
              plugins: [
                "advlist autolink lists link image charmap print preview anchor",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help wordcount",
              ],
              toolbar:
                "undo redo | formatselect | " +
                "bold italic backcolor | alignleft aligncenter " +
                "alignright alignjustify | bullist numlist outdent indent | " +
                "removeformat | help",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            }}
            onEditorChange={(newText) => SetBody1(newText)}
          /> */}
          {/* <hr /> */}
          <CKEditor
            editor={ClassicEditor}
            // data="<p>Hello!</p>"
            data={trip[0]?.itinerary ? trip[0]?.itinerary : "<p>Hello!</p>"}
            onReady={(editor) => {
              // You can store the "editor" and use when it is needed.
              // console.log("Editor is ready to use!", editor);
            }}
            onChange={(event, editor) => {
              const data = editor.getData();
              SetBody1(data);
              // console.log({ event, editor, data });
            }}
            onBlur={(event, editor) => {
              // console.log("Blur.", editor);
            }}
            onFocus={(event, editor) => {
              // console.log("Focus.", editor);
            }}
          />

          <button
            variant="contained"
            style={{
              display: "block",
              margin: "1rem",
              border: "none",
              padding: "0.5rem 1rem",
              color: "white",
              background: "blue",
              borderRadius: "10px",
              fontSize: "1.3rem",
              cursor: "pointer",
            }}
          >
            {isLoading ? "sending..." : "submit"}
          </button>
        </form>
      </div>
      {/* <div className="left2">
        <div className="" style={{ padding: "1rem" }}>
          <div dangerouslySetInnerHTML={{ __html: body }} />
        </div>
      </div> */}
    </div>
  );
};

export default Itenary;

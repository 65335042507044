import React, { useState, useContext } from "react";
import Navbar from "../../components/navbar/Navbar";
import TextField from "@mui/material/TextField";
import Sidebar from "../../components/sidebar/Sidebar";
import { MenuContext } from "../../context/MenuContext";
//
import Button from "@mui/material/Button";
import "./AddNewUser.scss";
// import axios from "axios";
import axios from "../../API/axios";
import { toast } from "react-toastify";
const CREATE_USER = "/user/register";

const AddNewUser = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { userToCreate, setUserToCreate, setSucces } = useContext(MenuContext);
  // console.log(userToCreate);
  const [message, setMessage] = useState({});
  const [errMsg, setErrMsg] = useState("");
  const [values, setValues] = useState({
    firstName: userToCreate ? userToCreate?.firstName : "",
    lastName: userToCreate ? userToCreate?.lastName : "",
    age: userToCreate ? userToCreate?.age : "",
    gender: userToCreate ? userToCreate?.gender : "",
    email: userToCreate ? userToCreate?.email : "",
    address: userToCreate ? userToCreate?.address : "",
    dialCode: userToCreate ? userToCreate?.dialCode : "",
    phoneNumber: userToCreate ? userToCreate?.phoneNumber : "",
  });

  console.log(userToCreate);
  //
  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const {
      firstName,
      lastName,
      age,
      gender,
      email,
      address,
      dialCode,
      phoneNumber,
    } = values;

    try {
      const response = await axios.post(
        CREATE_USER,
        {
          firstName,
          lastName,
          age,
          gender,
          email,
          address,
          dialCode,
          phoneNumber,
          userType: "user",
        },
        {
          headers: {
            "Content-Type": "application/json",
            token: `${JSON.parse(localStorage.getItem("data22"))}`,
          },
        }
      );
      setMessage(response?.data);
      setIsLoading(false);

      console.log(response);
      if (response?.data.msg) {
        toast(response?.data.msg);
        setUserToCreate({});
        document.getElementById("myInputU1").value = "";
        document.getElementById("myInputU2").value = "";
        document.getElementById("myInputU3").value = "";
        document.getElementById("myInputU4").value = "";
        document.getElementById("myInputU5").value = "";
        document.getElementById("myInputU6").value = "";
        document.getElementById("myInputU7").value = "";
        document.getElementById("myInputU8").value = "";
      }

      setSucces((prev) => !prev);
    } catch (err) {
      // console.log(err?.response?.data?.code);
      if (err?.response?.data?.code === 409) {
        setErrMsg("User With This Email Already Exist");
        setIsLoading(false);
        setUserToCreate({});
        document.getElementById("myInputU1").value = "";
        document.getElementById("myInputU2").value = "";
        document.getElementById("myInputU3").value = "";
        document.getElementById("myInputU4").value = "";
        document.getElementById("myInputU5").value = "";
        document.getElementById("myInputU6").value = "";
        document.getElementById("myInputU7").value = "";
        document.getElementById("myInputU8").value = "";
      } else if (!err?.response) {
        setErrMsg("No Server Response");
        setIsLoading(false);
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
        console.log(errMsg);
        setIsLoading(false);
      } else {
        setErrMsg("Failed to create");
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="newTrip">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        {/* {message?.password && (
          <div
            style={{
              boxShadow: "2px 4px 10px 1px rgba(0,0, 0,.3)",
              margin: "1rem auto",
              padding: "1rem",
              width: "40%",
              fontSize: "1.2rem",
            }}
          >
            <p>
              Password Generated:{" "}
              <em style={{ color: "rgb(91, 91, 247)" }}>
                {" " + message?.password}
              </em>
            </p>
          </div>
        )} */}
        <div style={{ marginTop: "0rem" }} className="bottom">
          <form className="newForm">
            <div className="form-group">
              <TextField
                id="myInputU1"
                fullWidth
                label="firstName"
                name="firstName"
                // id="fullWidth"
                value={values?.firstName}
                style={{ margin: "1rem 0" }}
                onChange={handleChange}
              />
              <TextField
                fullWidth
                id="myInputU2"
                label="lastName"
                name="lastName"
                // id="fullWidth"
                value={values?.lastName}
                style={{ margin: "1rem 0" }}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <TextField
                fullWidth
                id="myInputU3"
                label="Date Of Birth"
                name="age"
                // id="fullWidth"
                value={values?.age.slice(0, 10)}
                style={{ margin: "1rem 0" }}
                onChange={handleChange}
              />
              <TextField
                fullWidth
                label="gender"
                name="gender"
                id="myInputU4"
                // id="fullWidth"
                value={values?.gender}
                style={{ margin: "1rem 0" }}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <TextField
                fullWidth
                type="email"
                label="email"
                name="email"
                value={values?.email}
                // id="fullWidth"
                id="myInputU5"
                style={{ margin: "1rem 0" }}
                onChange={handleChange}
              />
              <TextField
                fullWidth
                label="address"
                name="address"
                // id="fullWidth"
                id="myInputU6"
                value={values?.address}
                style={{ margin: "1rem 0" }}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <TextField
                fullWidth
                label="Country Code"
                name="dialCode"
                value={values?.dialCode}
                // id="fullWidth"
                id="myInputU7"
                style={{ margin: "1rem 0" }}
                onChange={handleChange}
              />
              <TextField
                fullWidth
                label="Phone Number"
                name="phoneNumber"
                // id="fullWidth"
                id="myInputU8"
                value={values?.phoneNumber}
                style={{ margin: "1rem 0" }}
                onChange={handleChange}
              />
            </div>
            <Button variant="contained" onClick={handleSubmit}>
              {isLoading ? "Loading" : "Submit"}
            </Button>
            <div style={{ color: "green", margin: "1.5rem" }}>
              <p>
                {message?.msg &&
                  "User has been successfully created. Login details have been emailed to the user"}
              </p>
              {errMsg && <p>{errMsg}</p>}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddNewUser;

import React, { useContext } from "react";
import "./Sidebar.scss";
import LOGO1 from "../../img/TKE_logo.png";
import DashboardIcon from "@mui/icons-material/Dashboard";
import {
  AccountCircleOutlined,
  PersonOutlineOutlined,
  PowerInputOutlined,
  StoreOutlined,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import { DarkModeContext } from "../../context/darkModeContext";
import CancelIcon from "@mui/icons-material/Cancel";
import { MenuContext } from "../../context/MenuContext";
const Sidebar = () => {
  const { dispatch } = useContext(DarkModeContext);
  const { dispatchMenu } = useContext(MenuContext);

  const { Menu } = useContext(MenuContext);

  return (
    <div className={`sidebar ${Menu ? "activate" : "deactivate"}`}>
      <div className="top">
        <Link to="/" style={{ textDecoration: "none" }}>
          <div className="logo">
            <img
              style={{
                width: "50%",
                height: "auto",
              }}
              src={LOGO1}
              alt=""
            />
          </div>
        </Link>
        <CancelIcon
          className="cancelIcon"
          style={{ cursor: "pointer" }}
          onClick={() => dispatchMenu({ type: "CLOSE" })}
        />
      </div>
      <hr />
      <div className="center">
        <ul>
          <div className="title">MAIN</div>
          <Link to="/" style={{ textDecoration: "none" }}>
            <li>
              <DashboardIcon />
              <span>Dashboard</span>
            </li>
          </Link>

          <div className="title">LIST</div>

          <Link to="/trips" style={{ textDecoration: "none" }}>
            <li>
              <StoreOutlined />
              <span>Trip Requests</span>
            </li>
          </Link>
          <Link to="/users" style={{ textDecoration: "none" }}>
            <li>
              <PersonOutlineOutlined />
              <span>Users</span>
            </li>
          </Link>
          <Link to="/register-user" style={{ textDecoration: "none" }}>
            <li>
              <StoreOutlined />
              <span>Add New User</span>
            </li>
          </Link>
          <Link to="/banner" style={{ textDecoration: "none" }}>
            <li>
              <StoreOutlined />
              <span>Upload banner</span>
            </li>
          </Link>

          <div className="title">USER</div>
          <Link to="/createAdmin" style={{ textDecoration: "none" }}>
            <li>
              <AccountCircleOutlined />
              <span>Create Admin</span>
            </li>
          </Link>

          <Link to="/" style={{ textDecoration: "none" }}>
            <li
              onClick={() => {
                // localStorage.removeItem("data22");
                localStorage.clear();
                window.location.reload();
              }}
            >
              <PowerInputOutlined />
              <span>Logout</span>
            </li>
          </Link>
          {/* <div className="title">COLOR OPTION</div> */}
        </ul>
      </div>
      {/* <div className="bottom">
        <div
          className="colorOption"
          onClick={() => dispatch({ type: "DARK" })}
        ></div>
        <div
          className="colorOption"
          onClick={() => dispatch({ type: "LIGHT" })}
        ></div>
      </div> */}
    </div>
  );
};

export default Sidebar;

import React, { useContext, useState } from "react";
import "./Login.scss";
// import axios from "axios";
import axios from "../../API/axios";
import { useNavigate } from "react-router-dom";
import { MenuContext } from "../../context/MenuContext";
//
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Stack } from "@mui/material";
import { toast } from "react-toastify";
const FORGOT_URL = "/admin/forgot-super-admin-password";

const ForgotPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [values, setValues] = useState({
    loginId: "",
    password: "",
  });
  const { setRecoverToken } = useContext(MenuContext);
  const [errMsg, setErrMsg] = useState("");
  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const { loginId } = values;

    try {
      const response = await axios.post(FORGOT_URL, { loginId });

      setRecoverToken(response?.data?.token);
      toast.success(response?.data?.msg);
      setErrMsg(response?.data?.msg);
      setIsLoading(false);
      navigate("/reset_password");
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
        setIsLoading(false);
      } else if (err.response?.status === 400) {
        setErrMsg("incorrect Login Id");
        setIsLoading(false);
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
        console.log(errMsg);
        setIsLoading(false);
      } else {
        setErrMsg("Failed, check input");
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="cont__login">
      <div className="login">
        <Stack
          sx={{
            color: "blue",
            fontWeight: 600,
            fontSize: "1.3rem",
            margin: "1rem 0",
          }}
        >
          Get Token To Recover Super Admin
        </Stack>
        <form className="format">
          <TextField
            fullWidth
            label="Login Id"
            name="loginId"
            id="fullWidth"
            style={{ margin: "1rem 0 1.3rem 0" }}
            onChange={handleChange}
          />

          <Button variant="contained" onClick={handleSubmit}>
            {isLoading ? "Loading" : "submit"}
          </Button>
        </form>
        <p style={{ color: "red" }}>{errMsg}</p>
      </div>
    </div>
  );
};

export default ForgotPassword;

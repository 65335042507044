import React from "react";
import "./Widget.scss";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
// import PersonIcon from "@mui/icons-material/Person";
// import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import LocalAirportSharpIcon from "@mui/icons-material/LocalAirportSharp";
const Widgets = ({ type, data1, data2, data3, data100 }) => {
  let data;

  switch (type) {
    case "Trips":
      data = {
        title: "Trips",
        isMoney: false,
        link: "See all",
        icon: (
          <LocalAirportSharpIcon
            className="icon"
            fontSize="small"
            style={{ backgroundColor: "rgba(255,0,0,.2)" }}
          />
        ),
        amount: data100?.length,
      };
      break;
    case "New Trip Requests":
      data = {
        title: "New Trip Requests",
        isMoney: false,
        link: "See all",
        icon: (
          <AddShoppingCartIcon
            className="icon"
            fontSize="small"
            style={{ backgroundColor: "#6433d833" }}
          />
        ),
        amount: data1?.length,
      };
      break;
    case "Trip Requests (Prospects)":
      data = {
        title: "Trip Requests (Prospects)",
        isMoney: false,
        link: "Trip Request (prospect)",
        icon: (
          <AddShoppingCartIcon
            className="icon"
            fontSize="small"
            style={{ backgroundColor: "#62941267" }}
          />
        ),
        amount: data2?.length,
      };
      break;
    case "Trip Requests (Users)":
      data = {
        title: "Trip Requests (Users)",
        isMoney: true,
        link: "See details",
        icon: (
          <AddShoppingCartIcon
            className="icon"
            fontSize="small"
            style={{ backgroundColor: "#d634a033" }}
          />
        ),
        amount: data3?.length,
      };
      break;
    default:
      console.log("nothing...");
      break;
  }
  return (
    <div className="widget">
      <div className="left">
        <div className="title">{data.title}</div>
        <div className="counter">
          {/* {data.isMoney && "$"} */}
          {data.amount}
        </div>
        {/* <span className="link">See All Users</span> */}
      </div>
      <div className="right">
        <div className="percentage positive">
          {data.amount >= 1 && (
            <ArrowUpwardIcon style={{ visibility: "hidden" }} />
          )}
          {data.amount <= 0 && (
            <ArrowDownwardIcon style={{ visibility: "hidden" }} />
          )}
          {/* {diff}% */}
          &nbsp;
        </div>
        {data.icon}
      </div>
    </div>
  );
};

export default Widgets;

import React, { useContext, useEffect, useState } from "react";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
// import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useParams } from "react-router-dom";
import "./NewTrip.scss";
import { MenuContext } from "../../context/MenuContext";
import OverView from "../../components/PublisheTrip/OverView/OverView";
import Itenary from "../../components/PublisheTrip/Itenary/Itenary";
import Visa from "../../components/PublisheTrip/Visa/Visa";
import TripReview from "../../components/PublisheTrip/TripReview/TripReview";
import Payment from "../../components/PublisheTrip/Payment/Payment";
import Agreement from "../../components/PublisheTrip/Agreement/Agreement";
import TravelConfirmation from "../../components/PublisheTrip/TravelConfirmation/TravelConfirmation";
import FlightDetail from "../../components/PublisheTrip/FlightDetail/FlightDetail";
import axios from "../../API/axios";
import UploadInstruction from "../../components/PublisheTrip/UploadInstruction/UploadInstruction";
// const FETCH_TRIP = "/trips/get-all";

const index1 = () => {
  const localData = JSON.parse(localStorage.getItem("index008"));
  return localData ? localData : 0;
};
const NewTrip = ({ title, inputs }) => {
  const { id } = useParams();
  const [value, setValue] = useState(0);
  const { dispatchMenu, tripAll } = useContext(MenuContext);
  const [isLoading, setIsLoading] = useState(false);
  const [fetch, setFetch] = useState(false);
  const [trip, setTrip] = useState([]);
  const [tripById, setTripById] = useState([]);
  // console.log(fetch);
  const fetchTrips = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`/trips/get-all?_id=${id}`, {
        headers: {
          "Content-Type": "application/json",
          token: `${JSON.parse(localStorage.getItem("data22"))}`,
        },
      });
      const { data } = response?.data;
      setTripById(data);
      // console.log(data);
      setIsLoading(false);
      // console.log(topReferrals);
    } catch (err) {
      console.log(err.response);
    }
  };
  useEffect(() => {
    fetchTrips();
  }, [id, fetch]);

  const tripCategory = [
    {
      id: "1",
      category: "Overview",
    },
    {
      id: "2",
      category: "Flight Details",
    },
    {
      id: "3",
      category: "Agreements",
    },
    {
      id: "4",
      category: "Itinerary",
    },
    {
      id: "5",
      category: "Travel Confirmation",
    },
    {
      id: "6",
      category: "Payment",
    },
    {
      id: "7",
      category: "Visa",
    },
    {
      id: "8",
      category: "Trip Review",
    },
    {
      id: "9",
      category: "Upload Instruction",
    },
  ];

  useEffect(() => {
    dispatchMenu({ type: "CLOSE" });
  }, []);

  // console.log(trip);
  return (
    <div className="newTrip">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>
            {title} | {trip?.user?.firstName}
          </h1>
        </div>
        <div className="bottom">
          <div className="right">
            <div className="content1">
              {tripCategory.map((item, index) => {
                // console.log(tripCategory[index]);
                return (
                  <div
                    key={index}
                    onClick={() => {
                      setValue(index);
                      localStorage.setItem("index008", JSON.stringify(index));
                    }}
                    className={`sidebar-row ${
                      index === value && "active-side"
                    }`}
                    // className="sidebar-row active-side"
                  >
                    <span>&#8658; {item.category}</span>
                  </div>
                );
              })}
            </div>
          </div>
          {value === 0 && <OverView _id={id} trip={tripById} />}
          {value === 1 && (
            <FlightDetail _id={id} trip={tripById} setFetch={setFetch} />
          )}
          {value === 2 && (
            <Agreement _id={id} trip={tripById} setFetch={setFetch} />
          )}
          {value === 3 && (
            <Itenary _id={id} trip={tripById} setFetch={setFetch} />
          )}
          {value === 4 && (
            <TravelConfirmation _id={id} trip={tripById} setFetch={setFetch} />
          )}
          {value === 5 && (
            <Payment _id={id} trip={tripById} setFetch={setFetch} />
          )}
          {value === 6 && <Visa _id={id} trip={tripById} setFetch={setFetch} />}
          {value === 7 && <TripReview _id={id} />}
          {value === 8 && (
            <UploadInstruction _id={id} trip={tripById} setFetch={setFetch} />
          )}
        </div>
      </div>
    </div>
  );
};

export default NewTrip;

import React, { useContext } from "react";
import { MenuContext } from "../../context/MenuContext";
import "./Widget2.scss";
import { Link, useNavigate } from "react-router-dom";
import LocalAirportSharpIcon from "@mui/icons-material/LocalAirportSharp";
const TKEServices = ({ type, singleUser }) => {
  const navigate = useNavigate();
  const { setUserToCreate } = useContext(MenuContext);
  let data;

  // console.log(singleUser);

  switch (type) {
    case "TKE Service":
      data = {
        title: "Membership Details",
        isMoney: false,
        link: "Add Details",
        icon: (
          <LocalAirportSharpIcon
            className="icon"
            fontSize="small"
            style={{ backgroundColor: "rgba(255,0,0,.2)" }}
          />
        ),
        amount: 1,
      };
      break;
    default:
      console.log("nothing...");
      break;
  }
  return (
    <div className="widget2">
      <div className="left">
        <div className="title">{data.title}</div>
        <div
          // to={"/services/1"}
          style={{
            fontSize: "1.5rem !important",
            textDecoration: "none",
            background: "#5d1589",
            cursor: "pointer",
            padding: "0.5rem 1rem",
            borderRadius: "5px",
            color: "#fff",
          }}
          className="counter"
          onClick={(e) => {
            e.preventDefault();
            navigate("/services/1");
            setUserToCreate(singleUser);
          }}
        >
          {/* {data.isMoney && "$"} */}
          {singleUser?.membership ? "update details" : data.link}
        </div>
        {/* <span className="link">See All Users</span> */}
      </div>
      <div className="right">
        <div className="percentage positive">
          {/* {data.amount >= 1 && <ArrowUpwardIcon />}
          {data.amount <= 0 && <ArrowDownwardIcon />} */}
          {/* {diff}% */}
          &nbsp;
        </div>
        {/* {data.icon} */}
      </div>
    </div>
  );
};

export default TKEServices;

import { createContext, useReducer, useState, useEffect } from "react";
// import { useLocation } from "react-router-dom";
import MenuReducer from "./MenuReducer";
import axios from "../API/axios";
const FETCH_ALL_TRIP_REQUESTs = "/questionnaires/get-all";
const FETCH_ALL_USERS = "/admin/users";
const FETCH_TRIPS = "/trips/get-all";

const INITIAL_STATE = {
  Menu: false,
};

export const MenuContext = createContext(INITIAL_STATE);

const localData1 = () => {
  const localData = JSON.parse(localStorage.getItem("tripAll"));
  return localData ? localData : [];
};
const localUsers = () => {
  const localUsers1 = JSON.parse(localStorage.getItem("users"));
  return localUsers1 ? localUsers1 : [];
};
const localAdminUsers = () => {
  const localAdminUsers2 = JSON.parse(localStorage.getItem("adminUsers"));
  return localAdminUsers2 ? localAdminUsers2 : [];
};

export const MenuContextProvider = ({ children }) => {
  // const location = useLocation();
  const [adminId, setAdminId] = useState("");
  const [recoverToken, setRecoverToken] = useState(null);
  const [state, dispatchMenu] = useReducer(MenuReducer, INITIAL_STATE);
  const [adminUsers, setAdminUsers] = useState(localAdminUsers);
  const [userTripReq, setUserTripReq] = useState([]);
  const [prospectTripReq, setProspectTripReq] = useState([]);
  const [monthlyTripReq, setMonthlyTripReq] = useState([]);
  const [newTripReq, setNewTripReq] = useState([]);
  const [tripRequests, setTripRequests] = useState(
    JSON.parse(localStorage.getItem("tripRequests"))
  );
  const [tripAll, setTripAll] = useState(localData1);
  const [users, setUsers] = useState(localUsers);
  const [userToCreate, setUserToCreate] = useState({});
  const [generalBanner, setGeneralBanner] = useState(null);
  const [succes, setSucces] = useState(false);
  const [succes1, setSucces1] = useState(false);
  const [succes2, setSucces2] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // console.log(succes);
  const fetchUSers = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(FETCH_ALL_USERS, {
        headers: {
          "Content-Type": "application/json",
          token: `${JSON.parse(localStorage.getItem("data22"))}`,

          // Authorization: `Bearer ${JSON.parse(localStorage.getItem("data"))}`,
        },
      });
      const { data } = response?.data;
      // setUsers(data);
      setUsers(data.reverse());
      setIsLoading(false);
      // console.log(topReferrals);
    } catch (err) {
      console.log(err.response);
    }
  };
  //
  const fetchTripRequests = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(FETCH_ALL_TRIP_REQUESTs, {
        headers: {
          "Content-Type": "application/json",
          token: `${JSON.parse(localStorage.getItem("data22"))}`,

          // Authorization: `Bearer ${JSON.parse(localStorage.getItem("data"))}`,
        },
      });
      const { data } = response?.data;
      setTripRequests(data?.allTripRequest);
      // setTripRequests(data?.allTripRequest.reverse());
      setUserTripReq(data?.usersTripRequest);
      setProspectTripReq(data?.prospectsTripRequest);
      setNewTripReq(data?.newRequests);
      // setNewTripReq(data?.newRequests.reverse());
      setMonthlyTripReq(data?.tripRequestMonthlyCount);
      setIsLoading(false);
    } catch (err) {
      console.log(err.response);
    }
  };
  const fetchTrips = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(FETCH_TRIPS, {
        headers: {
          "Content-Type": "application/json",
          token: `${JSON.parse(localStorage.getItem("data22"))}`,

          // Authorization: `Bearer ${JSON.parse(localStorage.getItem("data"))}`,
        },
      });
      const { data } = response?.data;
      // console.log(data?.tripsMonthlyCount);
      setTripAll(data);
      setIsLoading(false);
      // console.log(topReferrals);
    } catch (err) {
      console.log(err.response);
    }
  };

  // useEffect(() => {
  //   fetchUSers();
  // }, [succes]);
  useEffect(() => {
    fetchTripRequests();
  }, [succes1]);

  // useEffect(() => {
  //   fetchTrips();
  // }, [succes2]);

  // useEffect(() => {
  //   localStorage.setItem("users", JSON.stringify(users)); // it set user base on 1st load and chnages made on users
  // }, [users]);

  // useEffect(() => {
  //   localStorage.setItem("tripAll", JSON.stringify(tripAll));
  // }, [tripAll]);
  useEffect(() => {
    localStorage.setItem("tripRequests", JSON.stringify(tripRequests));
  }, [tripRequests]);
  useEffect(() => {
    localStorage.setItem("adminUsers", JSON.stringify(adminUsers));
  }, [adminUsers]);

  return (
    <MenuContext.Provider
      value={{
        fetchUSers,
        fetchTrips,
        //
        Menu: state.Menu,
        dispatchMenu,
        adminUsers,
        setAdminUsers,
        users,
        tripRequests,
        setTripRequests,
        succes,
        setSucces,
        setSucces1,
        setSucces2,
        succes2,
        isLoading,
        setIsLoading,
        // newrows,
        userTripReq,
        prospectTripReq,
        newTripReq,
        monthlyTripReq,
        userToCreate,
        setUserToCreate,
        tripAll,
        setTripAll,
        generalBanner,
        setGeneralBanner,
        //
        adminId,
        setAdminId,
        recoverToken,
        setRecoverToken,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};

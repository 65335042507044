import React, { useState } from "react";
import { Link } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "../TableComponent.scss";
import "./PaymentTable.scss";
//
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import AgreementDelete from "../../latestModal/ModalsForAgreement/AgreementDelete";
import AgreementUpdate from "../../latestModal/ModalsForAgreement/AgreementUpdate";

//
const AgreementTable = ({ row, editItem, setFetch, setLocal, _id, local }) => {
  return (
    <TableContainer
      component={Paper}
      style={{ margin: "1rem" }}
      className="table"
      sx={{ minHeight: 400 }}
    >
      <Table sx={{ minWidth: 350 }} aria-label="simple table" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell className="tableCell" align="center">
              Title
            </TableCell>
            <TableCell className="tableCell" align="center">
              Agreement Link
            </TableCell>
            <TableCell className="tableCell" align="center">
              Status
            </TableCell>
            <TableCell className="tableCell" align="center">
              More
            </TableCell>
          </TableRow>
        </TableHead>
        {row?.map((item, index) => {
          return (
            <TableBody key={index}>
              <TableRow>
                <TableCell className="tableCell" align="center">
                  {item?.title}
                </TableCell>
                <TableCell className="tableCell" align="center">
                  <a
                    // href={item?.document_link}
                    href={
                      item?.document_link?.includes("https://")
                        ? item?.document_link
                        : "https://" + item?.document_link
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View
                  </a>
                </TableCell>
                <TableCell className="tableCell" align="center">
                  {item?.status}
                </TableCell>
                <TableCell className="tableCell" align="center">
                  <PopupState variant="popover" popupId="demo-popup-menu">
                    {(popupState) => (
                      <React.Fragment>
                        <Button
                          variant="contained"
                          {...bindTrigger(popupState)}
                        >
                          Action <ExpandMoreIcon />
                        </Button>
                        <Menu {...bindMenu(popupState)}>
                          <MenuItem
                            onClick={() => {
                              editItem(item._id);
                              // popupState.close();
                            }}
                            // onClick={popupState.close}
                          >
                            <AgreementUpdate
                              local1={local}
                              _id={_id}
                              setFetch={setFetch}
                              setLocal1={setLocal}
                              row={row}
                            />
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              editItem(item._id);
                              // popupState.close();
                            }}
                            // onClick={popupState.close}
                          >
                            <AgreementDelete
                              local1={local}
                              _id={_id}
                              setFetch={setFetch}
                              setLocal1={setLocal}
                            />
                          </MenuItem>
                          {/* <MenuItem onClick={popupState.close}>Logout</MenuItem> */}
                        </Menu>
                      </React.Fragment>
                    )}
                  </PopupState>

                  {/*  */}
                  {/*  */}
                </TableCell>
              </TableRow>
            </TableBody>
          );
        })}
      </Table>
    </TableContainer>
  );
};

export default AgreementTable;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "../TableComponent.scss";
// import "./PaymentTable.scss";

//

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import FlightUpdate from "../../latestModal/ModalsForFlight/FlightUpdate";
import FlightDelete from "../../latestModal/ModalsForFlight/FlightDelete";

//

const FlightDetailsTable = ({
  rows,
  editItem,
  setFetch,
  setLocal,
  _id,
  local,
}) => {
  rows = rows?.sort((a, b) => new Date(a?.date) - new Date(b?.date));

  return (
    <TableContainer
      component={Paper}
      style={{ margin: "1rem" }}
      className="table"
      sx={{ minHeight: 400 }}
    >
      <Table sx={{ minWidth: 350 }} aria-label="simple table" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell className="tableCell" align="center">
              Location
            </TableCell>
            <TableCell className="tableCell" align="center">
              Date
            </TableCell>
            <TableCell className="tableCell" align="center">
              More
            </TableCell>
          </TableRow>
        </TableHead>
        {rows?.map((item, index) => {
          return (
            <TableBody key={item?._id}>
              <TableRow>
                <TableCell className="tableCell" align="center">
                  {item?.location}
                </TableCell>
                <TableCell className="tableCell" align="center">
                  {item?.date}
                </TableCell>
                <TableCell className="tableCell" align="center">
                  <PopupState variant="popover" popupId="demo-popup-menu">
                    {(popupState) => (
                      <React.Fragment>
                        <Button
                          variant="contained"
                          {...bindTrigger(popupState)}
                        >
                          Action <ExpandMoreIcon />
                        </Button>
                        <Menu {...bindMenu(popupState)}>
                          <MenuItem
                            onClick={() => {
                              editItem(item._id);
                              // popupState.close();
                            }}
                            // onClick={popupState.close}
                          >
                            <FlightUpdate
                              local1={local}
                              _id={_id}
                              setFetch={setFetch}
                              setLocal1={setLocal}
                              row={rows}
                            />
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              editItem(item._id);
                              // popupState.close();
                            }}
                            // onClick={popupState.close}
                          >
                            <FlightDelete
                              local1={local}
                              _id={_id}
                              setFetch={setFetch}
                              setLocal1={setLocal}
                            />
                          </MenuItem>
                          {/* <MenuItem onClick={popupState.close}>Logout</MenuItem> */}
                        </Menu>
                      </React.Fragment>
                    )}
                  </PopupState>

                  {/*  */}
                </TableCell>
              </TableRow>
            </TableBody>
          );
        })}
      </Table>
    </TableContainer>
  );
};

export default FlightDetailsTable;

import React, { useContext, useState } from "react";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import "./SingleTrip.scss";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
// import axios from "../../API/axios";
import { MenuContext } from "../../context/MenuContext";
import axios from "axios";
// const CHANGE_ADMIN = "/change-other-admin-password";

const ResetOtherAdmin = () => {
  const { adminId } = useContext(MenuContext);
  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = useState({
    loginId1: "",
    password: "",
  });
  console.log(adminId);
  const [errMsg, setErrMsg] = useState("");

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const { loginId1, password } = values;

    // const data = {
    //     loginId: adminId
    // }

    try {
      const response = await axios.post(
        "https://tke-travel.onrender.com/api/v1/admin/change-other-admin-password",
        { loginId: adminId, password },
        {
          headers: {
            "Content-Type": "application/json",
            token: `${JSON.parse(localStorage.getItem("data22"))}`,
          },
        }
      );
      setErrMsg(response?.data?.msg);
      setIsLoading(false);
      // navigate("/");
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
        setIsLoading(false);
      } else if (err.response?.status === 400) {
        setErrMsg("Login Id or Password");
        setIsLoading(false);
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
        console.log(errMsg);
        setIsLoading(false);
      } else {
        setErrMsg("Login Failed");
        setIsLoading(false);
      }
    }
  };

  // const removefromArray = (item) => (myData = myData.splice(item, 1));

  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        <div
          className="top"
          style={{
            marginLeft: "20%",
          }}
        >
          <div
            className="left"
            style={{
              textAlign: "center",
            }}
          >
            <h1 className="title">Change Admin Password</h1>
            <div className="item">
              <div className="details">
                <form className="newForm">
                  <TextField
                    fullWidth
                    // label="Login Id"
                    name="loginId"
                    id="fullWidth"
                    placeholder={adminId}
                    sx={{
                      margin: "1rem 0",
                      "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled":
                        {
                          opacity: 1,
                          "-webkit-text-fill-color": "green",
                          fontSize: "1.5rem",
                        },
                    }}
                    // onChange={handleChange}
                    disabled
                  />
                  <TextField
                    fullWidth
                    label="New Password"
                    name="password"
                    id="fullWidth"
                    style={{ margin: "1rem 0" }}
                    onChange={handleChange}
                  />
                  <Button variant="contained" onClick={handleSubmit}>
                    {isLoading ? "Loading" : "Submit"}
                  </Button>
                </form>

                <p style={{ color: "green" }}>{errMsg}</p>
              </div>
            </div>
          </div>
          <div
            className="right"
            style={{
              padding: "1rem",
              visibility: "hidden",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default ResetOtherAdmin;

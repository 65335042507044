import React, { useContext, useState } from "react";
import { TextField, Button } from "@mui/material";
import axios from "../../../API/axios";
import VisaTable from "../../table/tripTables/VisaTable";
import { toast } from "react-toastify";

const POST_VISA = "/trips/visa/add";

// const dataSaved = () => {
//   const localData = JSON.parse(localStorage.getItem("datas003"));
//   return localData ? localData : [];
// };
// const [datas11, setDatas11] = useState(
//   trip[0]?.visa?.documents ? trip[0]?.visa?.documents : []
// );
const Visa = ({ _id, trip, setFetch }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [local1, setLocal1] = useState(null);
  const [title, setTitle] = useState("");
  const [link, setLink] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const datas11 = trip[0]?.visa;

  const validateForm = () => {
    let formIsValid = true;

    if (!title) {
      formIsValid = false;
    }

    if (!link) {
      formIsValid = false;
    }

    return formIsValid;
  };

  const editItem = (id) => {
    const idPos = datas11?.findIndex((item) => item._id === id);
    if (idPos >= 0) {
      return setLocal1(datas11[idPos]);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return toast.error("enter all input fields");
    }

    const data = {
      _id: _id,
      title: title,
      document_link: link,
    };

    setIsLoading(true);
    try {
      const response = await axios.post(POST_VISA, data, {
        headers: {
          // "Content-Type": "multipart/form-data",
          "Content-Type": "application/json",
          token: `${JSON.parse(localStorage.getItem("data22"))}`,
        },
      });
      setIsLoading(false);
      // console.log(response?.data);

      if (response?.data.msg) {
        toast(response?.data.msg);
        setLocal1(null);
        document.getElementById("myInput9").value = "";
        document.getElementById("myInput10").value = "";
        setFetch((prev) => !prev);
      }
    } catch (error) {
      if (!error?.response) {
        setErrMsg("No Server Response");
        setIsLoading(false);
      } else if (error.response?.status === 500) {
        // setErrMsg("");
        toast.error("Enter all fields");
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="left">
      <div
        className="center"
        style={{ textAlign: "center", padding: "0.2rem 1rem" }}
      >
        <div className="center--segment" style={{ position: "relative" }}>
          <h4 style={{ margin: "1rem" }}>Visa</h4>
          <form className="" onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label="Visa title"
              name="title"
              id="myInput9"
              style={{ margin: "1rem 0" }}
              onChange={(e) => setTitle(e.target.value)}
            />
            <TextField
              fullWidth
              label="Visa Link"
              name="title"
              id="myInput10"
              style={{ margin: "1rem 0" }}
              onChange={(e) => setLink(e.target.value)}
            />

            {
              <button
                variant="contained"
                style={{
                  display: "block",
                  margin: "1rem",
                  border: "none",
                  padding: "0.5rem 1rem",
                  color: "white",
                  background: "blue",
                  borderRadius: "10px",
                  fontSize: "1.3rem",
                  cursor: "pointer",
                }}
              >
                {isLoading ? "saving..." : "save"}
              </button>
            }
          </form>
        </div>
      </div>
      <div className="ine">
        <VisaTable
          row={datas11}
          editItem={editItem}
          local1={local1}
          _id={_id}
          setFetch={setFetch}
          setLocal1={setLocal1}
        />
      </div>
    </div>
  );
};

export default Visa;

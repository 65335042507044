import React, { useContext, useEffect, useState } from "react";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import "./New.scss";
import { MenuContext } from "../../context/MenuContext";
import { useParams, useNavigate, Link } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import BespokeList from "./TripDetailsList/BespokeList";
import DestinationCel from "./TripDetailsList/DestinationCel";
// import axios from "axios";
import { toast } from "react-toastify";
import axios from "../../API/axios";
const MARK_AS_SEEN = "/admin/mark-seen";
const DELETE_QUESTIONNAIRE_REQUEST = "/questionnaires/delete";

const NewSeen = ({ title }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [showN, setShowN] = useState(false);
  const [showD, setShowD] = useState(false);
  const [seenNow, setSeenNow] = useState("");
  const [isLoading, setIsLoading] = useState("");
  const [isLoading1, setIsLoading1] = useState("");
  const { tripRequests, setUserToCreate, setSucces1 } = useContext(MenuContext);
  const [trip, setTrip] = useState({});
  const [errMsg, setErrMsg] = useState("");

  // console.log(JSON.parse(localStorage.getItem("data22")));

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    try {
      const response = await axios.patch(
        MARK_AS_SEEN,
        {
          _id: id,
        },
        {
          headers: {
            token: `${JSON.parse(localStorage.getItem("data22"))}`,
          },
        }
      );
      setSeenNow(response?.data?.data?.requestStatus);
      setSucces1((prev) => !prev);
      setErrMsg(response?.data.msg);
      if (response?.data?.msg) {
        toast(response?.data?.msg);
        // console.log(response?.data.msg);
      }
      setIsLoading(false);
      // window.location.reload();
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
        setIsLoading(false);
      } else {
        setErrMsg("Registration Failed");
        setIsLoading(false);
      }
    }
  };

  // const handleDelete = (e) => {
  //   e.preventDefault();
  //   const raw = {
  //     id: id,
  //   };
  //   let requestOptions = {
  //     method: "delete",
  //     headers: {
  //       "Content-Type": "application/json",
  //       token: `${JSON.parse(localStorage.getItem("data22"))}`,
  //     },
  //     body: JSON.stringify(raw),
  //     // redirect: "/",
  //   };

  //   fetch("http://localhost:4000/api/v1/questionnaires/delete", requestOptions)
  //     .then((response) => response.json())
  //     .then((result) => console.log(result))
  //     .catch((error) => {
  //       console.log("error", error);
  //     });
  // };

  const handleDelete = async (e) => {
    e.preventDefault();

    setIsLoading1(true);
    try {
      const response = await axios.delete(DELETE_QUESTIONNAIRE_REQUEST, {
        headers: {
          "Content-Type": "application/json",
          token: `${JSON.parse(localStorage.getItem("data22"))}`,
        },
        data: {
          id: id,
        },
      });
      if (response?.data?.msg) {
        toast(response?.data?.msg);
      }
      setSucces1((prev) => !prev);
      setIsLoading1(false);
      navigate("/");
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
        setIsLoading(false);
      } else {
        setErrMsg("Request Does not Exist");
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    setTrip(
      tripRequests.find((item) => {
        return item._id === id;
      })
    );
  }, [id]);

  // console.log(trip?.user?.userType);

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        {/* <div className="top">
          <h1>{title}</h1>
        </div> */}
        <div className="bottom">
          <div className="right">
            <form action="">
              <div
                style={{
                  display: "flex",
                  gap: "1rem",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                className="formInput"
              >
                <div>
                  <h2>Trip Details</h2>
                  <div>
                    {trip?.tripType === "bespoke" && (
                      <h4 style={{ marginTop: "1rem" }}>{trip?.tripType}</h4>
                    )}
                    {trip?.tripType === "celebration" && (
                      <h4 style={{ marginTop: "1rem" }}>
                        destination {trip?.tripType}
                      </h4>
                    )}
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    gap: "1rem",
                    margin: "1rem 0.2rem",
                  }}
                >
                  {trip?.requestStatus === "new" && (
                    <button
                      style={{
                        display: seenNow === "seen" ? "none" : "flex",
                        alignItems: "center",
                      }}
                      className="seenTrip"
                      onClick={handleSubmit}
                    >
                      <VisibilityIcon />
                      {isLoading ? "Loading!" : "Mark As Seen"}
                    </button>
                  )}

                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    {trip?.user?.userType === "prospect" && (
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          setShowN((prev) => !prev);
                        }}
                      >
                        Approve This User
                      </button>
                    )}
                    <div
                      style={{
                        position: "absolute",
                        top: "35px",
                        right: "0px",
                        boxShadow: "2px 4px 10px 1px rgba(0,0, 0,.3)",
                        padding: "0.4rem 0.6rem",
                        textDecoration: "none",
                        background: "#fff",
                        zIndex: "20",
                        minWidth: "150px",
                        height: "auto",
                        display: !showN ? "none" : "flex",
                        alignItems: "center",
                        // fontSize: "1rem",
                        fontWeight: "bolder",
                        flexDirection: "column",
                      }}
                    >
                      <p
                        style={{
                          margin: "0.4rem auto",
                        }}
                      >
                        Are You Sure
                      </p>
                      <div
                        style={{
                          margin: "0.4rem",
                          display: "flex",
                          gap: "10px",
                        }}
                      >
                        <button
                          style={{
                            background: "grey",
                            width: "50%",
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            setShowN((prev) => !prev);
                          }}
                        >
                          No
                        </button>{" "}
                        <button
                          style={{
                            background: "grey",
                            width: "50%",
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            setShowN((prev) => !prev);
                            navigate("/newUser");
                            setUserToCreate(trip?.user);
                          }}
                        >
                          Yes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* DELETE */}

                <div
                  style={{
                    position: "relative",
                  }}
                >
                  <button
                    style={{
                      backgroundColor: "orangered",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      setShowD((prev) => !prev);
                    }}
                  >
                    {isLoading1 ? "Deleting" : "Delete Request"}
                  </button>

                  <div
                    style={{
                      position: "absolute",
                      top: "35px",
                      right: "0px",
                      boxShadow: "2px 4px 10px 1px rgba(0,0, 0,.3)",
                      padding: "0.4rem 0.6rem",
                      textDecoration: "none",
                      background: "#fff",
                      zIndex: "20",
                      minWidth: "150px",
                      height: "auto",
                      display: !showD ? "none" : "flex",
                      alignItems: "center",
                      // fontSize: "1rem",
                      fontWeight: "bolder",
                      flexDirection: "column",
                    }}
                  >
                    <p
                      style={{
                        margin: "0.4rem auto",
                      }}
                    >
                      Are You Sure
                    </p>
                    <div
                      style={{
                        margin: "0.4rem",
                        display: "flex",
                        gap: "10px",
                      }}
                    >
                      <button
                        style={{
                          background: "grey",
                          width: "50%",
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          setShowD((prev) => !prev);
                        }}
                      >
                        No
                      </button>
                      <button
                        style={{
                          background: "grey",
                          width: "50%",
                        }}
                        onClick={handleDelete}
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                </div>
                {/*  */}
              </div>
              <div className="divid__trip">
                {trip?.tripType === "bespoke" && <BespokeList trip={trip} />}
                {trip?.tripType === "celebration" && (
                  <DestinationCel trip={trip} />
                )}
                {trip?.tripType === "celebration destination" && (
                  <DestinationCel trip={trip} />
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewSeen;

import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import axios from "../../../API/axios";
const Flight_UPDATE = "/trips/flight-details";

export default function FlightUpdate({
  local1,
  _id,
  setFetch,
  setLocal1,
  row,
}) {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [values, setValues] = useState({
    location: "",
    date: "",
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { location, date } = values;
    const data = {
      _id: _id,
      details_id: local1._id,
      location: location ? location : local1.location,
      date: date ? date : local1.date,
    };
    setIsLoading(true);
    try {
      const response = await axios.patch(Flight_UPDATE, data, {
        headers: {
          token: `${JSON.parse(localStorage.getItem("data22"))}`,
        },
      });
      setIsLoading(false);
      // console.log(response?.data);
      // setSucces2((prev) => !prev);
      if (response?.data.msg) {
        toast(response?.data.msg);
        setLocal1(null);
        setFetch((prev) => !prev);
      }
    } catch (err) {
      // console.log(err?.response?.data?.code);
      if (err?.response?.data?.code === 409) {
        setErrMsg("Failed");
        setIsLoading(false);
      } else if (!err?.response) {
        setErrMsg("No Server Response");
        setIsLoading(false);
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
        console.log(errMsg);
        setIsLoading(false);
      } else {
        setErrMsg("Failed");
        setIsLoading(false);
      }
    }
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  return (
    <div>
      <button
        style={{
          border: "none",
          background: "none",
          fontSize: "1rem",
          cursor: "pointer",
        }}
        onClick={handleClickOpen}
      >
        Edit
      </button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Update This Item</DialogTitle>
        <form className="" onSubmit={handleSubmit}>
          <DialogContent>
            <DialogContentText>
              You can only change the title, else if you want to change the
              document/image you can delete and resend again
            </DialogContentText>

            <TextField
              autoFocus
              name="location"
              margin="dense"
              label="Location"
              type="text"
              fullWidth
              variant="standard"
              defaultValue={local1 ? local1?.location : values.location}
              onChange={handleChange}
            />
            <TextField
              autoFocus
              name="date"
              margin="dense"
              label="Date"
              type="text"
              fullWidth
              variant="standard"
              defaultValue={local1 ? local1?.date : values.date}
              onChange={handleChange}
            />
          </DialogContent>
          <button
            style={{
              color: "white",
              border: "none",
              background: "#1976d2",
              padding: "0.2rem 0.6rem",
              fontSize: "1.2rem",
              marginLeft: "1.5rem",
              cursor: "pointer",
              borderRadius: "5px",
              // pointerEvents: !trueh && "none",
            }}
          >
            {isLoading ? "updating.." : "Save Update"}
          </button>
        </form>

        {/* <button
          style={{
            color: "white",
            border: "none",
            background: !trueh ? "#1976d2" : "",
            padding: "0.2rem 0.6rem",
            fontSize: "1.2rem",
            margin: "1.5rem auto",
            cursor: "pointer",
            borderRadius: "5px",
            width: "12%",
            textAlign: "center",
          }}
          onClick={() => {
            updateDatas();
            setTrueh(true);
          }}
        >
          Edit
        </button> */}
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

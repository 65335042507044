import React, { useContext, useState } from "react";
import "./Login.scss";
// import axios from "axios";
import axios from "../../API/axios";
import { Link, useNavigate } from "react-router-dom";
import { MenuContext } from "../../context/MenuContext";
//
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Stack, Typography } from "@mui/material";
import { blueGrey } from "@mui/material/colors";
const LOGIN_URL = "/admin/login";

const Login = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [values, setValues] = useState({
    loginId: "",
    password: "",
  });
  const { setGeneralBanner, setAdminUsers } = useContext(MenuContext);
  const [errMsg, setErrMsg] = useState("");
  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const { loginId, password } = values;

    try {
      const response = await axios.post(LOGIN_URL, { loginId, password });
      // console.log(response?.data?.data?.banner);
      localStorage.setItem(
        "data22",
        JSON.stringify(response?.data?.data?.token)
      );
      // setGeneralBanner(response?.data?.data?.banner?.link);
      localStorage.setItem(
        "generalBanner",
        JSON.stringify(response?.data?.data?.banner?.link)
      );
      setAdminUsers(response?.data?.data?.admins);
      // console.log(response?.data?.data?.admins);
      setErrMsg(response?.msg);
      setIsLoading(false);
      navigate("/");
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
        setIsLoading(false);
      } else if (err.response?.status === 400) {
        setErrMsg("incorrect Username or Password");
        setIsLoading(false);
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
        console.log(errMsg);
        setIsLoading(false);
      } else {
        setErrMsg("Login Failed");
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="cont__login">
      <div className="login">
        <h3>Login</h3>
        <form className="format">
          <TextField
            fullWidth
            label="Login Id"
            name="loginId"
            id="fullWidth"
            style={{ margin: "1rem 0" }}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            type="password"
            label="Password"
            name="password"
            id="fullWidth"
            style={{ margin: "1rem 0" }}
            onChange={handleChange}
          />
          <Stack
            sx={{
              margin: "1rem",
              cursor: "pointer",
              textAlign: "left",
            }}
          >
            <Link to="/forgot_password">
              <Typography
                sx={{
                  color: "blue",
                  "&:hover": {
                    color: "gray",
                  },
                }}
              >
                Forgot Password
              </Typography>
            </Link>
          </Stack>
          <Button variant="contained" onClick={handleSubmit}>
            {isLoading ? "Loading" : "Login"}
          </Button>
        </form>
        <p style={{ color: "red" }}>{errMsg}</p>
      </div>
    </div>
  );
};

export default Login;

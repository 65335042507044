import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { Button } from "@mui/material";
import { MenuContext } from "../context/MenuContext";
import axios from "../API/axios";
const GENERAL_BANNER = "/admin/upload-userbanner";

const UserBanner = ({ emailId, userBana }) => {
  const { setSucces2 } = useContext(MenuContext);
  const [isLoading2, setIsLoading2] = useState(false);
  const [image, setImage] = useState(null);
  const [localUsaBanner, setLocalUsaBanner] = useState(null);

  // Validate Banner Size
  const imgSizing = () => {
    if (image?.size > 2000000) {
      return toast.error("file size is too large, image should not exceed 2mb");
    }
  };

  // USER BANNER
  const handleSubBanner = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("image", image);
    formData.append("user", emailId);

    console.log([...formData]);
    if (imgSizing()) {
      return;
    }
    setIsLoading2(true);
    try {
      const response = await axios.post(GENERAL_BANNER, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          token: `${JSON.parse(localStorage.getItem("data22"))}`,
        },
      });
      setIsLoading2(false);
      console.log(response?.data);
      setLocalUsaBanner(response?.data?.data);
      if (response?.data.msg) {
        toast(response?.data.msg);
        // console.log(response?.data.msg);
      }
    } catch (error) {
      if (!error?.response) {
        toast.error("No Server Response");
        setIsLoading2(false);
      } else if (error.response?.status === 500) {
        // setErrMsg("");
        toast.error("File too large, image should not be more than 2mb");
        setIsLoading2(false);
      }
    }
  };

  const handleImage = (event) => {
    setImage(event.target.files[0]);
  };

  return (
    <div className="">
      <div style={{ maxWidth: "200px", maxHeight: "200px" }} className="">
        <img
          style={{ maxWidth: "250px", maxHeight: "150px" }}
          src={
            image ? URL.createObjectURL(image) : userBana
            // : "https://t4.ftcdn.net/jpg/04/70/29/97/360_F_470299797_UD0eoVMMSUbHCcNJCdv2t8B2g1GVqYgs.jpg"
          }
          alt=""
        />
      </div>
      <div className="">
        <form
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
            margin: "2rem 0 1rem 0",
          }}
          action=""
          onSubmit={handleSubBanner}
        >
          <div className="formInput">
            Upload New Banner
            <label htmlFor="file" className="icon">
              <FileUploadIcon />
            </label>
            <input
              type="file"
              //   onChange={(e) => setFile(e.target.files[0])}
              onChange={handleImage}
              name=""
              placeholder="John Doe"
              id="file"
              style={{ display: "none" }}
            />
          </div>
          <button>{isLoading2 ? "uploading" : "update Banner"}</button>
        </form>
      </div>
    </div>
  );
};

export default UserBanner;
